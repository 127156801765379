<script setup>
   import { reactive, computed,ref} from 'vue'
   import axios from 'axios'
   import VueAxios from 'vue-axios'
   import { loadScript } from '@/helpers/helpers';

    const Name = ref("");
    const Description = ref("");
    const date = ref("");
    const Time = ref("");
</script>
<template>
    <!--begin::Main-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::toolbar-->
        <!--begin::toolbar-->
        <div class="toolbar" id="kt_toolbar">
            <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
                <!--begin::Info-->
                <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                    <!--begin::Title-->
                    <h3 class="text-dark fw-bolder my-1">Crear</h3>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                        <li class="breadcrumb-item">
                            <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="javascript:;" @click="goToPath('/messages/reminders')" class="text-muted text-hover-primary">Recordatorio</a>
                        </li>
                        <li class="breadcrumb-item text-dark">Crear Recordatorio</li>
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
            </div>
        </div>
        <!--end::toolbar-->
        <!--end::toolbar-->
        <!--begin::Content-->
        <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container">
                <!--begin::Card-->
                <div class="card">
                    <!--begin::Card Body-->
                    <!--begin::Product Info-->
                    <div class="py-10 px-7 px-lg-12">
                        <div class="mb-4 fw-bolder fs-6"><h1>Recordatorio</h1></div>
                        <div class="mb-12"></div>
                        <!--begin::Input-->
                        <div class="mb-8">
                            <label class="fw-bolder">Nombre</label>
                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="Name" placeholder="" />
                        </div>
                        <div class="mb-8">
                            <label class="fw-bolder">Description</label>
                            <textarea class="form-control form-control-solid form-control-lg" v-model="Description" rows="3"> </textarea>
                        </div>
                        <div class="mb-8">
                            <label class="fw-bolder">Fecha</label>
                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="date" placeholder="" />
                        </div>
                        <div class="mb-8">
                            <label class="fw-bolder">Hora</label>
                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="Time" placeholder="" />
                        </div>
                        <button type="submit" class="btn btn-primary fw-bolder me-2 px-8">Guardar</button>
                        <button type="reset" @click="goToPath('/messages/reminders')" id="kt_sidebar_shop_filter_form_btn" class="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-8">Descartar</button>
                        <!--end::Input-->
                    </div>
                    <!--end::Product Info-->
                    <!--end::Card Body-->
                </div>
                <!--end::Card-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Main-->
</template>

<script>
export default {
    methods:{
      goToPath(path){
        this.$router.push(path)
      }
    }
}
</script>

<style>

</style>