<script setup>
import { ref, onMounted } from 'vue'
import GetData from '@/services/profile/profileService';

const item = ref()

onMounted(() => {
    GetData("Active").then((response) => {
                item.value = response.userData
                console.log(item.value)
			});
})
</script>
<template>
<!--begin::Main-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Cuenta</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item">Perfil</li>
                    <li class="breadcrumb-item text-dark">Cuenta</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Info-->
            <!--begin::Nav-->
            <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
                <a href="javascript:;" @click="goToPath('/profile/account')" class="btn btn-active-accent active fw-bolder ms-3">Perfil</a>
                <a href="javascript:;" @click="goToPath('/profile/settings')" class="btn btn-active-accent fw-bolder ms-3">Configuraciones</a>
            </div>
            <!--end::Nav-->
        </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content" >
        <!--begin::Container-->
        <div class="container">
            <!--begin::Profile Account-->
            <div class="card">
                <!--begin::Form-->
                <form class="form d-flex flex-center">
                    <div class="card-body mw-800px py-20" :class="{ active: index==0 }" data-action="list" v-if="item">
                        <!--begin::Form row-->
                        <div class="row mb-8">
                            <label class="col-lg-3 col-form-label">Nombre completo</label>
                            <div class="col-lg-9">
                                <div class="spinner spinner-sm spinner-primary spinner-right">
                                    <input class="form-control form-control-lg form-control-solid" type="text" v-model="item.fullName"/>
                                </div>
                            </div>
                        </div>
                        <!--end::Form row-->
                        <!--begin::Form row-->
                        <div class="row mb-8">
                            <label class="col-lg-3 col-form-label">Correo eléctronico</label>
                            <div class="col-lg-9">
                                <div class="input-group input-group-lg input-group-solid">
                                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="item.email" placeholder="Email" />
                                </div>
                            </div>
                        </div>
                        <!--end::Form row-->
                        <!--begin::Form row-->
                        <div class="row mb-8">
                            <label class="col-lg-3 col-form-label">Correo eléctronico de seguridad</label>
                            <div class="col-lg-9">
                                <div class="input-group input-group-lg input-group-solid">
                                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="item.emailBackup" placeholder="Email" />
                                </div>
                            </div>
                        </div>
                        <!--end::Form row-->
                        <!--begin::Form row-->
                        <div class="row mb-8">
                            <label class="col-lg-3 col-form-label">País</label>
                            <div class="col-lg-9">
                                <div class="input-group input-group-lg input-group-solid">
                                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="item.Country" placeholder="Country" />
                                </div>
                            </div>
                        </div>
                        <!--end::Form row-->
                        <!--begin::Form row-->
                        <div class="row mb-8">
                            <label class="col-lg-3 col-form-label">Télefono</label>
                            <div class="col-lg-9">
                                <div class="input-group input-group-lg input-group-solid">
                                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="item.phones" placeholder="Phone" />
                                </div>
                            </div>
                        </div>
                        <!--end::Form row-->
                        <!--begin::Form row-->
                        <div class="row align-items-center mb-3">
                            <label class="col-lg-3 col-form-label">Recuperación:</label>
                            <div class="col-lg-9">
                                <div class="d-flex align-items-center">
                                    <div class="form-check form-check-custom form-check-solid me-5">
                                        <input class="form-check-input" type="checkbox" checked="checked" id="inlineCheckbox1" value="option1" />
                                        <label class="form-check-label fw-bold" for="inlineCheckbox1">Correo eléctronico</label>
                                    </div>
                                    <div class="form-check form-check-custom form-check-solid me-5">
                                        <input class="form-check-input" type="checkbox" disabled id="inlineCheckbox2" value="option2" />
                                        <label class="form-check-label fw-bold" for="inlineCheckbox2">SMS</label>
                                    </div>
                                    <div class="form-check form-check-custom form-check-solid">
                                        <input class="form-check-input" type="checkbox" disabled id="inlineCheckbox3" value="option3" />
                                        <label class="form-check-label fw-bold" for="inlineCheckbox3">Teléfono</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--begin::Form row-->
                        <div class="separator separator-dashed my-10"></div>
                        <!--begin::Form row-->
                        <div class="row mb-8">
                            <label class="col-lg-3 col-form-label">Verificacion de 2 pasos</label>
                            <div class="col-lg-9">
                                <button type="button" class="btn btn-light-primary fw-bold btn-sm" disabled>Configurar inicio de sesión</button>
                                <div class="form-text">Despues de iniciar sesión se te pedirá informacion adicional para verificar que eres tú.
                                <a href="#" class="fw-bold">Leer más</a>.</div>
                            </div>
                        </div>
                        <!--end::Form row-->
                        <!--begin::Form row-->
                        <div class="row mb-13">
                            <label class="col-lg-3 col-form-label">Verificacion de cambio de contraseña</label>
                            <div class="col-lg-9">
                                <div class="form-check form-check-custom form-check-solid me-5">
                                    <input class="form-check-input" type="checkbox" id="customCheck5" value="option1" />
                                    <label class="form-check-label fw-bold" for="customCheck5">No se permite la recuperación de contraseña de la cuenta.</label>
                                </div>
                                <div class="form-text py-2">Para mas seguridad, se te solicitara via sms o correo electronico la confirmacion del cambio de contraseña.
                                <a href="#" class="fw-boldk">Leer más</a>.</div>
                                <button type="button" class="btn btn-light-danger fw-bold btn-sm">Desactivar cuenta</button>
                            </div>
                        </div>
                        <!--end::Form row-->
                        <!--begin::Form row-->
                        <div class="row">
                            <label class="col-lg-3 col-form-label"></label>
                            <div class="col-lg-9">
                                <button type="reset" class="btn btn-primary fw-bolder px-6 py-3 me-3">Guardar cambios</button>
                                <button type="reset" class="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3">Cancelar</button>
                            </div>
                        </div>
                        <!--end::Form row-->
                    </div>
                </form>
                <!--end::Form-->
            </div>
            <!--end::Profile Account-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Main-->
</template>

<script>
export default {
    name: 'Account',
    methods:{
      goToPath(path){
        this.$router.push(path)
    }
  }
};
</script>

<style>

</style>