<script setup>
import { ref, onMounted, inject } from 'vue'
import GetData from '@/services/companies/companiesService'
import GetDataShow from '@/services/companies/companiesServiceShow'
const items = ref()
const information = ref()
onMounted(() => {
    GetData("Active").then((response) => {
                items.value = response.data
			});
})
function ShowDataCompanies(id) {     
    GetDataShow(id).then((response) => {
                information.value = response
                console.log(information.value);
            });
}
</script>
<template>
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Compañias</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Listado de compañias</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
        </div>
    </div>
    <!--end::toolbar-->
<div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
    <!--begin::Container-->
    <div class="container">
        <!--begin::Layout-->
        <div class="flex-row-fluid">
            <!--begin::Section-->
            <!--begin::Table Widget 4-->
            <div class="card">
                <!--begin::Header-->
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark">Listado de Compañias</span>
                    </h3>
                    <div class="card-toolbar">
                        <a href="javascript:;" @click="goToPath('/companies/create')" class="btn btn-primary fw-bolder fs-7">Nueva Compañia</a>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body py-0">
                    <!--begin::Table-->
                    <div class="table-responsive">
                        <table class="table align-middle border-gray-100" id="kt_advance_table_widget_4">
                            <thead>
                                <tr class="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                                    <td class="ps-0 w-30px py-5">
                                        <div class="form-check form-check-custom form-check-sm form-check-solid me-2">
                                            <input class="form-check-input" type="checkbox" value="1" />
                                        </div>
                                    </td>
                                    <td class="ps-0 min-w-50px py-5">Id</td>
                                    <td class="min-w-250px py-5">Nombre</td>
                                    <td class="min-w-150px py-5">Dominio</td>
                                    <td class="min-w-200px py-5">Direccion</td>
                                    <td class="min-w-200px py-5">Pais</td>
                                    <td class="min-w-75px pe-0 text-center py-5">Opciones</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :class="{ active: index==0 }" data-action="list" v-for="(item, index) in items">
                                    <td class="ps-0 py-6">
                                        <div class="form-check form-check-custom form-check-sm form-check-solid me-2">
                                            <input class="form-check-input" type="checkbox" value="1" />
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.id }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.name }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.domain }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.direction }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.country }}</span>
                                    </td>
                                    <td class="pe-0 text-end">
                                        <a href="javascript:;" @click="ShowDataCompanies(item.id)" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx-3"  title="Ver compañia" data-bs-container="body" data-bs-toggle="modal" data-bs-target="#kt_modal_show">
                                            <!--begin::Svg Icon | path: icons/stockholm/Communication/Write.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-building"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a href="javascript:;" @click="editCompanies('/companies/edit/' ,item.id)" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx" data-bs-toggle="tooltip" title="Editar compañia" data-bs-container="body">
                                            <!--begin::Svg Icon | path: icons/stockholm/Communication/Write.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-edit"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a href="javascript:;" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx-3" data-bs-toggle="modal" title="Configuracion" data-bs-container="body" data-bs-target="#kt_modal_config">
                                            <!--begin::Svg Icon | path: icons/stockholm/Communication/Write.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-cog fa-spin"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a href="#" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx" title="Eliminar compañia" data-bs-container="body" data-bs-toggle="modal" data-bs-target="#kt_modal_delete">
                                            <!--begin::Svg Icon | path: icons/stockholm/General/Trash.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-trash"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
            <!--end::Table Widget 4-->
            <!--end::Section-->
        </div>
        <!--end::Layout-->
    </div>
    <!--end::Container-->
    <div class="modal bg-white fade" tabindex="-1" id="kt_modal_show">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content shadow-none">
                <div class="modal-body">
                    <div class="card shadow-none w-md-1000px w-xxl-1000px">
                        <!--begin::Card Body-->
                        <div class="card-body bg-light px-12 py-10">
                            <h3 class="fw-bolder fs-1 mb-1">
                                <a v-if="information" class="text-gray-800">{{information.name}}</a>
                            </h3>
                            <!--begin::Info-->
                            <table class="table table-borderless align-middle fw-bold">
                                <tr>
                                    <td class="text-gray-600 ps-0">Dirección:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.direction}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Dominio:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.domain}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Nombre del empleado encargado:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.incharge.employee_name}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Télefono del empleado encargado:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.incharge.employee_phones}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Dirección del empleado encargado:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.incharge.employee_direction}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Dpi del empleado encargado:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.incharge.employee_dpi}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Email del empleado encargado:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.incharge.employee_email}}</td>
                                </tr>
                            </table>
                            <!--end::Info-->
                        </div>
                        <!--end::Card Body-->
                    </div>
                    <!--begin::Sidebar-->
                    <div id="kt_sidebar" class="sidebar bg-info" data-kt-drawer="true" data-kt-drawer-name="sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '350px': '300px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_sidebar_toggler">
                        <!--begin::Sidebar Content-->
                        <div class="d-flex flex-column sidebar-body">
                            <!--begin::Sidebar Content-->
                            <div id="kt_sidebar_content" class="py-10 px-2 px-lg-8">
                                <div class="hover-scroll-y me-lg-n5 pe-lg-4" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-offset="0px" data-kt-scroll-wrappers="#kt_sidebar_content">
                                    <!--begin::Card-->
                                    <div class="card bg-info">
                                        <!--begin::Body-->
                                        <div class="card-body px-0">
                                            <div class="pt-0">
                                                <!--begin::Chart-->
                                                <div class="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center" style="background-image:url('frontend/assets/media/svg/illustrations/bg-2.svg')">
                                                    <div class="position-absolute mb-7">
                                                        <div class="symbol symbol-circle symbol-100px overflow-hidden d-flex flex-center z-index-1">
                                                            <span class="symbol-label bg-warning align-items-end">
                                                                <img alt="Logo" src="frontend/assets/media/svg/avatars/016-boy-7.svg" class="mh-75px" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div id="kt_user_chart" style="height: 200px"></div>
                                                </div>
                                                <!--end::Chart-->
                                                <!--begin::Items-->
                                                <div class="pt-4">
                                                    <!--begin::Title-->
                                                    <div class="text-center pb-12">
                                                        <!--begin::Username-->
                                                        <h3 v-if="information" class="fw-bolder text-white fs-2 pb-4">{{information.name}}</h3>
                                                        <!--end::Username-->
                                                        <!--end::Action-->
                                                        <span v-if="information" class="fw-bolder fs-6 text-primary px-4 py-2 rounded bg-white bg-opacity-10">{{information.domain}}</span>
                                                        <!--begin::Action-->
                                                    </div>
                                                    <!--end::Title-->
                                                    <!--begin::Row-->
                                                    <div class="row row-cols-2 px-xl-12 sidebar-toolbar">
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">38</span>
                                                                <span class="fw-bold">Ultima hora de conexion</span>
                                                            </a>
                                                        </div>
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">204</span>
                                                                <span class="fw-bold">Usuarios</span>
                                                            </a>
                                                        </div>
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">76</span>
                                                                <span class="fw-bold">Tiempo online</span>
                                                            </a>
                                                        </div>
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">90%</span>
                                                                <span class="fw-bold">Perfil completado</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <!--end::Row-->
                                                </div>
                                                <!--end::Items-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Card-->
                                </div>
                            </div>
                            <!--end::Sidebar Content-->
                        </div>
                        <!--end::Sidebar Content-->
                    </div>
                    <!--end::Sidebar-->
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-light btn-bg-warning" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_delete">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Eliminar usuario</h5>

                <!--begin::Close-->
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                    <span class="svg-icon svg-icon-2x"></span>
                </div>
                <!--end::Close-->
            </div>

            <div class="modal-body">
                <p>¿Estás seguro de que deseas eliminar la compañia?</p>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary">Confirmar</button>
            </div>
        </div>
    </div>
    </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_config">
    <br><br><br><br><br><br><br><br><br>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Cambiar contraseña</h5>

                <!--begin::Close-->
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                    <span class="svg-icon svg-icon-2x"></span>
                </div>
                <!--end::Close-->
            </div>

            <div class="modal-body">
                <form>
                    <div class="input-group">
                        <input type="text" name="users" class="form-control" id="users" placeholder="Usuarios disponibles" >&nbsp&nbsp
                        <input type="text" name="emails" class="form-control" id="emails" placeholder="Correos por mes" >
                    </div>
                    <br>
                    <div class="form-check form-switch">
                        <label class="form-check-label" for="flexSwitchCheckChecked">Activado:</label>
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                    </div>
                    <br>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Confirmar</button>
            </div>
        </div>
    </div>
</div>
<!--end::Content-->
</template>

<script>
export default {
    methods:{
      goToPath(path){
        this.$router.push(path)
      },
      editCompanies(path, id){
        this.$router.push({
        name: "companiesedit",
        params:{
            id: id,
        }
        });
    },
  }
}
</script>

<style>

</style>