import apiService from '../apiService';
import Swal from 'sweetalert2'
import router  from '@/router';
import sessionService from '@/services/data/sessionService';

export default function StoreData(
    FullName: string,
    DisplayName: string,
    Direction: string,
    Phone: string,
    Email: string,
    EmailBackup: string,
    Password: string,
    PasswordConfirm: string,
    CompanyId: string): Promise<any> {
    return apiService.post('/api/users/store/json', {
        fullName:FullName,
        displayName: DisplayName,
        direction: Direction,
        phones: Phone,
        email: Email,
        emailBackup: EmailBackup,
        password: Password,
        companyId: CompanyId,
        userRols:1,
      })
      .then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'Accion completada',
            text: response.data,
            confirmButtonText:"Aceptar"
        });
        router.push('/users/list')
      })
      .catch((error) => {
         
          // Manejo de errores
          Swal.fire({
              icon: 'error',
              title: 'Error - '+error.response.data.error,
              text: error.response.data.message,
              confirmButtonText:"Aceptar"
          })
          return false;
      });
  }
  