import CryptoJS from 'crypto-js';

const secretKey = 'GuateMailSecret'; // Clave secreta para encriptar/desencriptar

// Función para encriptar la información
function Encript(valor: string): string {
  const encryptedValue = CryptoJS.AES.encrypt(valor, secretKey).toString();
  return encryptedValue;
}

// Función para desencriptar la información
function Decript(valorEncriptado: string): string {
  const decryptedBytes = CryptoJS.AES.decrypt(valorEncriptado, secretKey);
  const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return decryptedValue;
}

// Función para guardar el valor encriptado en la sesión
function Store(variable: string, val: string): void {
  const Val = Encript(val.toString());
  sessionStorage.setItem(variable, Val);
}

// Función para obtener y desencriptar el valor de la sesión
function Get(variable: string): string | null {
  const Val = sessionStorage.getItem(variable);
  if (Val) {
    return Decript(Val);
  }
  return null;
}
function Delete(variable: string){
    sessionStorage.removeItem(variable);
}
export { Store, Get,Delete };
const sessionService = {
    Store,
    Get,
    Delete
  };
  
  export default sessionService;