<script setup>
import { onMounted, onUnmounted,onUpdated } from 'vue'
import { loadScript } from '@/helpers/helpers';
onUpdated(() => {
	console.log("HOLA");
})
onMounted(() => {
		
	setTimeout(() => {
		loadScript('./frontend/assets/js/default/custom/apps/inbox/inbox.js')
			.then(() => {
				loadScript('./frontend/assets/js/default/custom/apps/inbox/lib.js')
					.then(() => {
						loadScript('./frontend/assets/js/default/custom/apps/inbox/compose.js')
						.then(() => {
						})
						.catch(() => {
							console.log("No cargado");
						});
					})
					.catch(() => {
						console.log("No cargado");
					});
			})
			.catch(() => {
				console.log("No cargado");
			});
		
      }, 1000);
})

</script>
<template>
		<!--begin::Main-->
		<!--begin::Root-->
		<div class="d-flex flex-column flex-root">
			<!--begin::Page-->
			<div class="page d-flex flex-row flex-column-fluid">
				<!--begin::Wrapper-->
				<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
					<Header />
					<router-view/>
					<Footer />
				</div>
				<!--end::Wrapper-->
			</div>
			<!--end::Page-->
		</div>
		<!--end::Root-->
		<!--begin::Header Search-->
		<div class="modal  fade" id="kt_header_search_modal" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen">
				<div class="modal-content shadow-none">
					<div class="container w-lg-800px">
						<div class="modal-header d-flex justify-content-end border-0">
							<!--begin::Close-->
							<div class="btn btn-icon btn-sm btn-light-primary ms-2" data-bs-dismiss="modal">
								<!--begin::Svg Icon | path: icons/stockholm/Navigation/Close.svg-->
								<span class="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>
								<!--end::Svg Icon-->
							</div>
							<!--end::Close-->
						</div>
						<div class="modal-body">
							<!--begin::Search-->
							<form class="pb-10">
								<input autofocus="" type="text" class="form-control bg-transparent border-0 fs-4x text-center fw-normal" name="query" placeholder="Buscar..." />
							</form>
							<!--end::Search-->
							<!--begin::Shop Goods-->
							<div class="py-10">
								<h3 class="fw-bolder mb-8">Atajos</h3>
								<!--begin::Row-->
								<div class="row g-5">
									<div class="col-sm-8">
										<div class="row g-5">
											<div class="col-sm-6">
												<div class="card overlay min-h-125px mb-5 shadow-none" @click="goToPath('/')">
													<div class="card-body d-flex flex-column p-0">
														<div class="overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded" style="background-image:url('frontend/assets/media/misc/loginimage.png')"></div>
														<div class="overlay-layer bg-white bg-opacity-50">
															<a href="javascript:;" @click="goToPath('/')"  class="btn btn-sm fw-bold btn-primary">General</a>
														</div>
													</div>
												</div>
												<div class="card overlay min-h-125px mb-5 shadow-none" @click="goToPath('/messages/out')">
													<div class="card-body d-flex flex-column p-0">
														<div class="overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded" style="background-image:url('frontend/assets/media/Menu/mail.png')"></div>
														<div class="overlay-layer bg-white bg-opacity-50">
															<a href="javascript:;" @click="goToPath('/messages/out')" class="btn btn-sm fw-bold btn-primary">Enviados</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-6">
												<div class="card card-stretch overlay mb-5 shadow-none min-h-250px" @click="goToPath('/messages/spam')">
													<div class="card-body d-flex flex-column p-0">
														<div class="overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded" style="background-image:url('frontend/assets/media/Menu/spam.png')"></div>
														<div class="overlay-layer bg-white bg-opacity-50">
															<a href="javascript:;" @click="goToPath('/messages/spam')" class="btn btn-sm fw-bold btn-primary">Spam</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="card card-stretch overlay mb-5 shadow-none min-h-250px" @click="goToPath('/more/calendar')">
											<div class="card-body d-flex flex-column p-0">
												<div class="overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded" style="background-image:url('frontend/assets/media/Menu/schedule.png')"></div>
												<div class="overlay-layer bg-white bg-opacity-50">
													<a href="javascript:;" @click="goToPath('/more/calendar')" class="btn btn-sm fw-bold btn-primary">Calendario</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!--end::Row-->
							</div>
							<!--end::Shop Goods-->
							<!--begin::Tutorials-->
							<div class="pb-10">
								<h3 class="text-dark fw-bolder fs-1 mb-6">Tutoriales</h3>
								<!--begin::List Widget 5-->
								<div class="card mb-5 shadow-none">
									<!--begin::Body-->
									<div class="card-body pt-2 px-0">
										<!--begin::Item-->
										<div class="d-flex mb-6">
											<!--begin::Icon-->
											<div class="me-1">
												<!--begin::Svg Icon | path: icons/stockholm/Navigation/Angle-right.svg-->
												<span class="svg-icon svg-icon-sm svg-icon-primary">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<polygon points="0 0 24 0 24 24 0 24" />
															<path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999)" />
														</g>
													</svg>
												</span>
												<!--end::Svg Icon-->
											</div>
											<!--end::Icon-->
											<!--begin::Content-->
											<div class="d-flex flex-column">
												<a href="javascript:;" @click="goToPath('/article')" class="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2">Como enviar un correo</a>
												<div class="fw-bold text-muted">En este tutorial aprenderas a enviar un correo</div>
											</div>
											<!--end::Content-->
										</div>
										<!--end::Item-->
										<!--begin::Item-->
										<div class="d-flex mb-6">
											<!--begin::Icon-->
											<div class="me-1">
												<!--begin::Svg Icon | path: icons/stockholm/Navigation/Angle-right.svg-->
												<span class="svg-icon svg-icon-sm svg-icon-primary">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<polygon points="0 0 24 0 24 24 0 24" />
															<path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999)" />
														</g>
													</svg>
												</span>
												<!--end::Svg Icon-->
											</div>
											<!--end::Icon-->
											<!--begin::Content-->
											<div class="d-flex flex-column">
												<a href="javascript:;" @click="goToPath('/article')"  class="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2">Como cambiar tu contraseña</a>
												<div class="fw-bold text-muted">Aprende a cambiar tu contraseña en sensillos pasos</div>
											</div>
											<!--end::Content-->
										</div>
										<!--end::Item-->
										<!--begin::Item-->
										<div class="d-flex mb-6">
											<!--begin::Icon-->
											<div class="me-1">
												<!--begin::Svg Icon | path: icons/stockholm/Navigation/Angle-right.svg-->
												<span class="svg-icon svg-icon-sm svg-icon-primary">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<polygon points="0 0 24 0 24 24 0 24" />
															<path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999)" />
														</g>
													</svg>
												</span>
												<!--end::Svg Icon-->
											</div>
											<!--end::Icon-->
											<!--begin::Content-->
											<div class="d-flex flex-column">
												<a href="javascript:;" @click="goToPath('/article')"  class="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2">Como añadir a favorito un correo</a>
												<div class="fw-bold text-muted">Aprende a seleccionar tus correos favoritos</div>
											</div>
											<!--end::Content-->
										</div>
										<!--end::Item-->
										<!--begin::Item-->
										<div class="d-flex mb-6">
											<!--begin::Icon-->
											<div class="me-1">
												<!--begin::Svg Icon | path: icons/stockholm/Navigation/Angle-right.svg-->
												<span class="svg-icon svg-icon-sm svg-icon-primary">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<polygon points="0 0 24 0 24 24 0 24" />
															<path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999)" />
														</g>
													</svg>
												</span>
												<!--end::Svg Icon-->
											</div>
											<!--end::Icon-->
											<!--begin::Content-->
											<div class="d-flex flex-column">
												<a href="javascript:;" @click="goToPath('/article')"  class="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2">Como cambiar tu foto de perfil</a>
												<div class="fw-bold text-muted">Cambia tu foto de perfil en sensillos pasos</div>
											</div>
											<!--end::Content-->
										</div>
										<!--end::Item-->
										<!--begin::Item-->
										<div class="d-flex mb-6">
											<!--begin::Icon-->
											<div class="me-1">
												<!--begin::Svg Icon | path: icons/stockholm/Navigation/Angle-right.svg-->
												<span class="svg-icon svg-icon-sm svg-icon-primary">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<polygon points="0 0 24 0 24 24 0 24" />
															<path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999)" />
														</g>
													</svg>
												</span>
												<!--end::Svg Icon-->
											</div>
											<!--end::Icon-->
											<!--begin::Content-->
											<div class="d-flex flex-column">
												<a href="javascript:;" @click="goToPath('/article')"  class="fs-6 fw-bolder text-hover-primary text-gray-800 mb-2">Como rescatar un correo de la papelera</a>
												<div class="fw-bold text-muted">Rescata un correo desde la papelera</div>
											</div>
											<!--end::Content-->
										</div>
										<!--end::Item-->
									</div>
									<!--end::Body-->
								</div>
								<!--end::List Widget 5-->
							</div>
							<!--end::Tutorials-->
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!--end::Header Search-->
		<!--begin::Modal Body Overlay-->
		<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Modal Title</h5>

						<!--begin::Close-->
						<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
							<span class="svg-icon svg-icon-2x"></span>
						</div>
						<!--end::Close-->
					</div>

					<!--begin::Overlay-->
					<div class="modal-body overlay overlay-block" align="center" >
						Cargando...

						<!--begin::Overlay Layer-->
						<div class="overlay-layer bg-dark bg-opacity-5">
							<div class="spinner-border text-primary" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
						<!--end::Overlay Layer-->
					</div>
					<!--end::Overlay-->
				</div>
			</div>
		</div>
		<!--end::Modal Body Overlay-->
		<!--begin::Mega Menu-->
		<div class="modal fade" id="kt_mega_menu_modal" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen">
				<div class="modal-content shadow-none">
					<div class="container">
						<div class="modal-header d-flex flex-stack border-0">
							<div class="d-flex align-items-center">
								<!--begin::Logo-->
								<a href="javascript:;" @click="goToPath('/')">
									<img alt="Logo" src="frontend/assets/media/logos/Logo4.png" class="h-60px" />
								</a>
								<!--end::Logo-->
							</div>
							<!--begin::Close-->
							<div class="btn btn-icon btn-sm btn-light-primary ms-2" data-bs-dismiss="modal">
								<!--begin::Svg Icon | path: icons/stockholm/Navigation/Close.svg-->
								<span class="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>
								<!--end::Svg Icon-->
							</div>
							<!--end::Close-->
						</div>
						
						<div class="modal-body">
							<!--begin::Row-->
							<div class="row py-10 g-5">
								<!--begin::Column-->
								<div class="col-lg-6 pe-lg-25">
									<!--begin::Row-->
									<div class="row">
										<div class="col-sm-4">
											<h3 class="fw-bolder mb-5">Correos</h3>
											<ul class="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/')">Recibidos</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/out')">Enviados</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/delete')">Eliminados</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/important')">Importantes</a>
												</li>
											</ul>
										</div>
										<div class="col-sm-4">
											<h3 class="fw-bolder mb-5">Categorias</h3>
											<ul class="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/social')">Social</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/forum')">Foros</a>
												</li>
											</ul>
										</div>
									</div>
									<!--end::Row-->
									<!--begin::Row-->
									<div class="row">
										<div class="col-sm-4">
											<h3 class="fw-bolder mb-5">Perfil</h3>
											<ul class="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/profile/account')">Cuenta</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/profile/settings')">Configuracion de Cuenta</a>
												</li>
											</ul>
										</div>
										<div class="col-sm-4">
											<h3 class="fw-bolder mb-5">Mas</h3>
											<ul class="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/spam')">Spam</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/schedule')">Programados</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/favorites')">Favoritos</a>
												</li>
												<li class="menu-item">
													<a class="menu-link ps-0 py-2" href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/all')">Todos</a>
												</li>
											</ul>
										</div>
									</div>
									<!--end::Row-->
								</div>
								<!--end::Column-->
								<!--begin::Column-->
								<div class="col-lg-6">
									<h3 class="fw-bolder mb-8">Ajustes rapidos</h3>
									<!--begin::Row-->
									<div class="row g-5">
										<div class="col-sm-4">
											<a href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/')" class="card bg-light-success hoverable min-h-125px shadow-none mb-5">
												<div class="card-body d-flex flex-column flex-center">
													<h3 class="fs-3 mb-2 text-dark fw-bolder">General</h3>
												</div>
											</a>
										</div>
										<div class="col-sm-4">
											<a href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/more/calendar')" class="card bg-light-danger hoverable min-h-125px shadow-none mb-5">
												<div class="card-body d-flex flex-column flex-center text-center">
													<h3 class="fs-3 mb-2 text-dark fw-bolder">Calendario</h3>
												</div>
											</a>
										</div>
										<div class="col-sm-4">
											<a href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/')" class="card bg-light-warning hoverable min-h-125px shadow-none mb-5">
												<div class="card-body d-flex flex-column flex-center text-center">
													<h3 class="fs-3 mb-2 text-dark text-hover-primary fw-bolder">Recibidos</h3>
												</div>
											</a>
										</div>
									</div>
									<!--end::Row-->
									<!--begin::Row-->
									<div class="row g-5">
										<div class="col-sm-8">
											<a href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/users/list')" class="card bg-light-primary hoverable min-h-125px shadow-none mb-5">
												<div class="card-body d-flex flex-column flex-center text-center">
													<h3 class="fs-3 mb-2 text-dark fw-bolder">Listado de Usuarios</h3>
												</div>
											</a>
											<!--begin::Row-->
											<div class="row g-5">
												<div class="col-sm-6">
													<a href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/companies/list')" class="card bg-light-warning hoverable shadow-none min-h-125px mb-5">
														<div class="card-body d-flex flex-column flex-center text-center">
															<h3 class="fs-3 mb-2 text-dark fw-bolder">Listado de compañias</h3>
														</div>
													</a>
												</div>
												<div class="col-sm-6">
													<a href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/reminders')" class="card bg-light-success hoverable shadow-none min-h-125px mb-5">
														<div class="card-body d-flex flex-column flex-center text-center">
															<h3 class="fs-3 mb-2 text-dark fw-bolder">Recordatorios</h3>
														</div>
													</a>
												</div>
											</div>
											<!--end::Row-->
										</div>
										<div class="col-sm-4">
											<a href="javascript:;" data-bs-dismiss="modal" @click="goToPath('/messages/notes')" class="card card-stretch mb-5 bg-light-info hoverable shadow-none min-h-250px">
												<div class="card-body d-flex flex-column p-0">
													<div class="d-flex flex-column flex-center text-center px-5 pt-10">
														<h3 class="fs-3 mb-2 text-dark fw-bolder">Notas</h3>
													</div>
													<div class="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom" style="background-image:url('frontend/assets/media/svg/illustrations/terms-1.svg')"></div>
												</div>
											</a>
										</div>
									</div>
									<!--end::Row-->
								</div>
								<!--end::Column-->
							</div>
							<!--end::Row-->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--end::Mega Menu-->
		<!--begin::Inbox compose drawer-->
		<div id="kt_inbox_compose" class="bg-white" data-kt-drawer="true" data-kt-drawer-name="inbox-compose" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '600px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_inbox_compose_toggle" data-kt-drawer-close="#kt_inbox_compose_close">
			<!--begin::Form-->
			<form id="kt_inbox_compose_form" class="card w-100">
				<!--begin::Header-->
				<div class="card-header" id="kt_inbox_compose_header">
					<h5 class="card-title">Redactar</h5>
					<div class="card-toolbar">
						<!--begin::Close-->
						<div class="btn btn-sm btn-icon btn-active-icon-primary me-n4" id="kt_inbox_compose_close">
							<!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
							<span class="svg-icon svg-icon-2x">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
								</svg>
							</span>
							<!--end::Svg Icon-->
						</div>
						<!--end::Close-->
					</div>
				</div>
				<!--end::Header-->
				<!--begin::Body-->
				<div class="card-body p-0" id="kt_inbox_compose_body">
					<!--begin::Subject-->
					<div id="kt_inbox_compose_subject">
						<!--begin::To-->
						<div class="d-flex align-items-center border-bottom inbox-to px-8 min-h-50px">
							<div class="text-gray-600 w-75px">Para:</div>
							<div class="d-flex align-items-center flex-grow-1">
								<input type="text" class="form-control border-0" name="" value="Chris Muller, Lina Nilson" />
							</div>
							<div class="ms-2">
								<span class="text-muted fw-bold cursor-pointer text-hover-primary me-2" data-inbox="cc-show">Cc</span>
								<span class="text-muted fw-bold cursor-pointer text-hover-primary" data-inbox="bcc-show">Cco</span>
							</div>
						</div>
						<!--end::To-->
						<!--begin::CC-->
						<div class="d-none align-items-center border-bottom inbox-to-cc ps-8 pe-5 min-h-50px">
							<div class="text-gray-600 w-75px">Cc:</div>
							<div class="flex-grow-1">
								<input type="text" class="form-control border-0" name="compose_cc" value="" />
							</div>
							<span class="btn btn-active-light-primary btn-sm btn-icon" data-inbox="cc-hide">
								<i class="la la-close"></i>
							</span>
						</div>
						<!--end::CC-->
						<!--begin::BCC-->
						<div class="d-none align-items-center border-bottom inbox-to-bcc ps-8 pe-5 min-h-50px">
							<div class="text-gray-600 w-75px">Cco:</div>
							<div class="flex-grow-1">
								<input type="text" class="form-control border-0" name="compose_bcc" value="" />
							</div>
							<span class="btn btn-active-light-primary btn-sm btn-icon" data-inbox="bcc-hide">
								<i class="la la-close"></i>
							</span>
						</div>
						<!--end::BCC-->
						<!--begin::Subject-->
						<div class="border-bottom">
							<input class="form-control border-0 px-8 min-h-45px" name="compose_subject" placeholder="Asunto" />
						</div>
						<!--end::Subject-->
					</div>
					<!--end::Subject-->
					<!--begin::Message-->
					<div id="kt_inbox_compose_editor" class="border-0 scroll-y" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_inbox_compose_header, #kt_inbox_compose_footer, #kt_inbox_compose_subject" data-kt-scroll-wrappers="#kt_inbox_compose_body" data-kt-scroll-offset="55px"></div>
					<!--end::Message-->
				</div>
				<!--end::Body-->
				<!--begin::Footer-->
				<div class="card-footer px-0 pt-0 pb-8 border-0" id="kt_inbox_compose_footer">
					<div class="border-bottom pb-8 px-8 mb-10">
						<!--begin::Attachments-->
						<div class="dropzone dropzone-queue" id="kt_inbox_compose_attachments">
							<div class="dropzone-items">
								<div class="dropzone-item" style="display:none">
									<div class="dropzone-file">
										<div class="dropzone-filename" title="some_image_file_name.jpg">
											<span data-dz-name="">some_image_file_name.jpg</span>
											<span class="fs-7">(
											<span data-dz-size="">340kb</span>)</span>
										</div>
										<div class="dropzone-error" data-dz-errormessage=""></div>
									</div>
									<div class="dropzone-progress">
										<div class="progress">
											<div class="progress-bar bg-primary" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" data-dz-uploadprogress=""></div>
										</div>
									</div>
									<div class="dropzone-toolbar">
										<span class="dropzone-delete" data-dz-remove="">
											<!--begin::Close-->
											<span class="btn btn-icon btn-sm btn-active-light-primary ms-2">
												<!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
												<span class="svg-icon svg-icon-2">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
														<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
													</svg>
												</span>
												<!--end::Svg Icon-->
											</span>
											<!--end::Close-->
										</span>
									</div>
								</div>
							</div>
						</div>
						<!--end::Attachments-->
					</div>
					<div class="d-flex flex-stack px-8">
						<!--begin::Actions-->
						<div class="d-flex align-items-center me-3">
							<!--begin::Send-->
							<button class="btn btn-primary me-4 px-6">Enviar</button>
							<!--end::Send-->
							<!--begin::Other-->
							<a href="#" class="btn btn-icon btn-active-light-primary me-2" id="kt_inbox_compose_attachments_select">
								<!--begin::Svg Icon | path: icons/duotune/files/fil022.svg-->
								<span class="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path opacity="0.3" d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z" fill="black" />
										<path d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</a>
							<a href="#" class="btn btn-icon btn-active-light-primary">
								<!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
								<span class="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black" />
										<path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</a>
							<!--end::Other-->
						</div>
						<!--end::Actions-->
						<!--begin::Toolbar-->
						<div class="d-flex align-items-center">
							<a href="#" class="btn btn-icon btn-active-light-primary me-2" data-bs-toggle="tooltip" title="Mas opciones">
								<!--begin::Svg Icon | path: icons/duotune/coding/cod001.svg-->
								<span class="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="black" />
										<path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</a>
						</div>
						<!--end::Toolbar-->
					</div>
				</div>
				<!--end::Footer-->
			</form>
			<!--end::Form-->
		</div>
		<!--end::Inbox compose drawer-->
		<!--begin::Scrolltop-->
		<div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
			<!--begin::Svg Icon | path: icons/stockholm/Navigation/Up-2.svg-->
			<span class="svg-icon">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<polygon points="0 0 24 0 24 24 0 24" />
						<rect fill="#000000" opacity="0.5" x="11" y="10" width="2" height="10" rx="1" />
						<path d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z" fill="#000000" fill-rule="nonzero" />
					</g>
				</svg>
			</span>
			<!--end::Svg Icon-->
		</div>
</template>

<script>
import FooterMessage from './pages/_FooterMessage.vue'
import Footer from './pages/_Footer.vue'
import Header from './pages/_Header.vue'
export default {
    components: {Header, Footer, FooterMessage},
    name:'Master',
	methods:{
      goToPath(path){
        this.$router.push(path)
    }
  }
};
</script>

<style>

</style>