<script setup>
</script>
<template>
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Historial</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Historial de usuario</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
        </div>
    </div>
    <!--end::toolbar-->
<div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
    <!--begin::Container-->
    <div class="container">
        <!--begin::Layout-->
        <div class="flex-row-fluid">
            <!--begin::Section-->
            <!--begin::Table Widget 4-->
            <div class="card">
                <!--begin::Header-->
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark">Historial de usuario</span>
                    </h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body py-0">
                    <!--begin::Table-->
                    <div class="table-responsive">
                        <table class="table align-middle border-gray-100" id="kt_advance_table_widget_4">
                            <thead>
                                <tr class="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                                    <td class="ps-0 w-30px py-5">
                                        <div class="form-check form-check-custom form-check-sm form-check-solid me-2">
                                            <input class="form-check-input" type="checkbox" value="1" />
                                        </div>
                                    </td>
                                    <td class="ps-0 min-w-25px py-5">Id</td>
                                    <td class="min-w-75px py-5">Acción</td>
                                    <td class="min-w-400px py-5">Mensaje</td>
                                    <td class="min-w-75px pe-0 text-end py-5">Fecha</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-0 py-6">
                                        <div class="form-check form-check-custom form-check-sm form-check-solid me-2">
                                            <input class="form-check-input" type="checkbox" value="1" />
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">1</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">Enviado</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">Se ha enviado un correo a papitas crispi </span>
                                    </td>
                                    <td class="pe-0 text-end">
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">11/11/2011</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
            <!--end::Table Widget 4-->
            <!--end::Section-->
        </div>
        <!--end::Layout-->
    </div>
    <!--end::Container-->
</div>
<!--end::Content-->
</template>



<script>


export default {
	name:'ListUsers',
    methods: {
        goToPath(path){
            this.$router.push(path)
        }

    },
 
};
</script>

<style>

</style>