<template>
<!--begin::Main-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Configuración</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item">Perfil</li>
                    <li class="breadcrumb-item text-dark">Configuraciones</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Info-->
            <!--begin::Nav-->
            <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
                <a href="javascript:;" @click="goToPath('/profile/account')" class="btn btn-active-accent fw-bolder ms-3">Perfil</a>
                <a href="javascript:;" @click="goToPath('/profile/settings')" class="btn btn-active-accent active fw-bolder ms-3">Configuraciones</a>
            </div>
            <!--end::Nav-->
        </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Profile Settings-->
            <div class="card">
                <!--begin::Form-->
                <form class="form d-flex flex-center">
                    <div class="card-body mw-800px py-20">
                        <div class="row">
                            <label class="col-xl-3"></label>
                            <div class="col-lg-9 col-xl-6">
                                <h5 class="fw-bold mb-6">Configurar notificaciones del correo:</h5>
                            </div>
                        </div>
                        <div class="mb-5 row align-items-center mb-2">
                            <label class="col-xl-3 col-lg-3 col-form-label fw-bold text-start text-lg-end">Notificaciones</label>
                            <div class="col-lg-9 col-xl-6 d-flex align-items-center">
                                <div class="form-check form-check-custom form-check-solid form-switch">
                                    <input class="form-check-input" type="checkbox" checked="checked" />
                                </div>
                            </div>
                        </div>
                        <div class="mb-5 row align-items-center">
                            <label class="col-xl-3 col-lg-3 col-form-label fw-bold text-start text-lg-end">Enviar copia a correo personal</label>
                            <div class="col-lg-9 col-xl-6">
                                <div class="form-check form-check-custom form-check-solid form-switch">
                                    <input class="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                        <div class="separator my-10"></div>
                        <div class="row">
                            <label class="col-xl-3"></label>
                            <div class="col-lg-9 col-xl-6">
                                <h5 class="fw-bold mb-6">Actividades relacionadas a los correos eléctronicos:</h5>
                            </div>
                        </div>
                        <div class="mb-8 row">
                            <label class="col-xl-3 col-lg-3 col-form-label fw-bold text-start text-lg-end">Cuando realizar un correo</label>
                            <div class="col-lg-9 col-xl-6">
                                <div class="form-check form-check-custom form-check-solid mb-3">
                                    <input class="form-check-input" type="checkbox" checked="checked" id="kt_checkbox_1" value="option111" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_1">Tienes nuevas notificaciones</label>
                                </div>
                                <div class="form-check form-check-custom form-check-solid mb-3">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_2" value="option112" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_2">Enviaste un mensaje directo</label>
                                </div>
                                <div class="form-check form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_3" value="option113" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_3">Alguien te ha añadido</label>
                                </div>
                            </div>
                        </div>
                        <div class="mb-8 row">
                            <label class="col-xl-3 col-lg-3 col-form-label fw-bold text-start text-lg-end">Cuando escalas un correo eléctronico</label>
                            <div class="col-lg-9 col-xl-6">
                                <div class="form-check form-check-custom form-check-solid mb-3">
                                    <input class="form-check-input" type="checkbox" checked="checked" id="kt_checkbox_4" value="option114" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_4">Nueva orden</label>
                                </div>
                                <div class="form-check form-check-custom form-check-solid mb-3">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_5" value="option115" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_5">Nuevo miembro</label>
                                </div>
                                <div class="form-check form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_6" value="option116" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_6">Nuevo miembro registrado</label>
                                </div>
                            </div>
                        </div>
                        <div class="separator my-10"></div>
                        <div class="row">
                            <label class="col-xl-3"></label>
                            <div class="col-lg-9 col-xl-6">
                                <h5 class="fw-bold mb-6">Actualizaciones de guatemail:</h5>
                            </div>
                        </div>
                        <div class="mb-8 row">
                            <label class="col-xl-3 col-lg-3 col-form-label fw-bold text-start text-lg-end">Te enviara un correo cuando:</label>
                            <div class="col-lg-9 col-xl-6">
                                <div class="form-check form-check-custom form-check-solid mb-3">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_7" value="option114" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_7">Noticias sobre actualizaciones</label>
                                </div>
                                <div class="form-check form-check-custom form-check-solid mb-3">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_8" value="option115" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_8">Consejos de un buen manejo de la plataforma</label>
                                </div>
                                <div class="form-check form-check-custom form-check-solid mb-3">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_9" value="option116" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_9">Correos no leidos</label>
                                </div>
                                <div class="form-check form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" id="kt_checkbox_10" value="option116" />
                                    <label class="form-check-label fw-bold text-gray-600" for="kt_checkbox_10">Noticias sobre nuestros nuevos servicios</label>
                                </div>
                            </div>
                        </div>
                        <!--begin::Form Group-->
                        <div class="mb-8 row pt-10">
                            <label class="col-lg-3 col-form-label"></label>
                            <div class="col-lg-9">
                                <button type="reset" class="btn btn-primary fw-bolder px-6 py-3 me-3">Guardar cambios</button>
                                <button type="reset" class="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3">Cancelar</button>
                            </div>
                        </div>
                        <!--end::Form Group-->
                    </div>
                </form>
                <!--end::Form-->
            </div>
            <!--end::Profile Settings-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Main-->
</template>

<script>
export default {
    name: 'Settings',
    methods:{
      goToPath(path){
        this.$router.push(path)
    }
  }
};
</script>

<style>

</style>