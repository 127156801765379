import apiService from '../apiService';
import Swal from 'sweetalert2'
import router  from '@/router';
import sessionService from '@/services/data/sessionService';

export default function StoreData(
    Name: string,
    Domain: string,
    Direction: string,
    EmployeeName: string,
    EmployeePhone: string,
    EmployeeDirection: string,
    EmployeeDpi: string,
    EmployeeEmail: string,
    ): Promise<any> {
    return apiService.post('/api/companies/store/json', {
        name: Name,
        domain: Domain,
        direction: Direction,
        employeeName: EmployeeName,
        employeePhones: EmployeePhone,
        employeeDirection: EmployeeDirection,
        EmployeeDpi: EmployeeDpi,
        EmployeeEmail: EmployeeEmail

      })
      .then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'Accion completada',
            text: response.data,
            confirmButtonText:"Aceptar"
        });
        router.push('/companies/list')
      })
      .catch((error) => {
         
          // Manejo de errores
          Swal.fire({
              icon: 'error',
              title: 'Error - '+error.response.data.error,
              text: error.response.data.message,
              confirmButtonText:"Aceptar"
          })
          return false;
      });
  }
  