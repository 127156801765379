import apiService from '../apiService';
import Swal from 'sweetalert2'
import router  from '@/router';
import sessionService from '@/services/data/sessionService';

export default function GetDataShow(id: number): Promise<any> {

    return apiService.post('/api/users/show/'+id+'/json', {})
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         
          // Manejo de errores
          Swal.fire({
              icon: 'error',
              title: 'Error - '+error.response.data.error,
              text: error.response.data.message,
              confirmButtonText:"Aceptar"
          })
          return false;
      });
  }