<script setup>
import { onMounted, ref } from 'vue'
import GetData from '@/services/messages/messageService'
const message = ref()
    function RefreshData() {
    GetData("Active").then((response) => {
                message.value = response.data
			});
  }
  onMounted(() => {
    RefreshData()
    
        })
</script>
<template>
  <!--begin::Main-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Notas</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Listado de notas</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
        </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Inbox-->
            <div class="d-flex flex-column flex-lg-row">
                <!--begin::List-->
                <div class="flex-lg-row-fluid ms-lg-12 d-block" id="kt_inbox_list">
                    <!--begin::Card-->
                    <div class="card mt-10 mt-lg-0">
                        <!--begin::Header-->
                        <div class="card-header border-0 py-5">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bolder text-dark">Listado de notas</span>
                            </h3>
                            <div class="card-toolbar">
                                <a href="javascript:;" @click="goToPath('/messages/createnote')" class="btn btn-primary fw-bolder fs-7">Nueva Nota</a>
                            </div>
                        </div>
                        <div class="card-header align-items-center card-px">
                            <!--begin::Toolbar-->
                            <div class="d-flex align-items-center">
                                <div class="form-check form-check-sm form-check-custom form-check-solid me-4 my-2" data-inbox="group-select">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                </div>
                                <div class="d-flex align-items-center me-1 my-2">
                                    <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Favoritos">
                                        <i class="fas fa-heart fs-6"></i>
                                    </a>
                                    <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Responder">
                                        <i class="fas fa-reply fs-6"></i>
                                    </a>
                                    <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Borrar">
                                        <i class="fas fa-trash fs-6"></i>
                                    </a>
                                </div>
                            </div>
                            <!--end::Toolbar-->
                            <!--begin::Pagination-->
                            <div class="d-flex align-items-center justify-content-sm-end text-end my-2">
                                <!--begin::Per Page Dropdown-->
                                <div class="d-flex align-items-center me-2">
                                    <span class="text-muted fw-bold me-2">1/247</span>
                                </div>
                                <!--end::Per Page Dropdown-->
                                <!--begin::Arrow Buttons-->
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Página Anterior" data-bs-container="body">
                                    <i class="fas fa-chevron-left fs-6"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Siguiente Página" data-bs-container="body">
                                    <i class="fas fa-chevron-right fs-6"></i>
                                </a>
                                <a @click="RefreshData()" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Actualizar" data-bs-container="body">
                                    <i style="font-size: 18px;" class="fa fa-redo-alt"></i>
                                </a>
                                <!--end::Arrow Buttons-->
                            </div>
                            <!--end::Pagination-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body px-0 overflow-auto">
                            <!--begin::Items-->
                            <div class="min-w-700px" data-inbox="list">
                                <!--begin::Item-->
                                <div class="d-flex align-items-start bg-hover-light card-px py-3" data-inbox="message" @click="goToPath('/messages/noteview')">
                                    <!--begin::Toolbar-->
                                    <div class="d-flex align-items-center">
                                        <!--begin::Actions-->
                                        <div class="d-flex align-items-center me-3" data-inbox="actions">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input class="form-check-input" type="checkbox" value="1" />
                                            </div>
                                            <a href="#" class="btn btn-icon btn-sm text-hover-warning active" data-bs-toggle="tooltip" data-bs-placement="right" title="Star">
                                                <i class="fas fa-star text-muted"></i>
                                            </a>
                                            <a href="#" class="btn btn-icon btn-sm text-hover-warning" data-bs-toggle="tooltip" data-bs-placement="right" title="Mark as important">
                                                <i class="fas fa-bookmark text-muted"></i>
                                            </a>
                                        </div>
                                        <!--end::Actions-->
                                        <!--begin::Author-->
                                        <div class="d-flex align-items-center flex-wrap w-xxl-200px me-3" data-bs-toggle="view">
                                            <!--begin::Symbol-->
                                            <div class="symbol symbol-40px me-4">
                                                <span class="symbol-label bg-light">
                                                    <img src="frontend/assets/media/svg/avatars/035-boy-15.svg" class="h-75 align-self-end" alt="" />
                                                </span>
                                            </div>
                                            <!--end::Symbol-->
                                            <a href="#" class="fw-bold text-gray-800 text-hover-primary">Sean Paul</a>
                                        </div>
                                        <!--end::Author-->
                                    </div>
                                    <!--end::Toolbar-->
                                    <!--begin::Info-->
                                    <div class="flex-grow-1 mt-2 me-2" data-bs-toggle="view">
                                        <div>
                                            <span class="fw-bolder fs-6 me-2">Digital PPV Customer Confirmation -</span>
                                            <span class="text-muted">Thank you for ordering UFC 240 Holloway vs Edgar Alternate camera angles...</span>
                                        </div>
                                        <div class="mt-2">
                                            <span class="badge badge-light-primary me-1">inbox</span>
                                            <span class="badge badge-light-danger">task</span>
                                        </div>
                                    </div>
                                    <!--end::Info-->
                                    <!--begin::Datetime-->
                                    <div class="mt-2 me-3 fw-bolder w-50px text-end" data-bs-toggle="view">8:30 PM</div>
                                    <!--end::Datetime-->
                                </div>
                                <!--end::Item-->
                            </div>
                            <!--end::Items-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::List-->
                <!--begin::View-->
                <div class="flex-lg-row-fluid ms-lg-12 d-none" id="kt_inbox_view">
                    <!--begin::Card-->
                    <div class="card">
                        <!--begin::Header-->
                        <div class="card-header align-items-center flex-wrap justify-content-between">
                            <!--begin::Left-->
                            <div class="d-flex align-items-center">
                                <a href="#" class="btn btn-active-light-primary btn-icon btn-sm me-6" data-bs-toggle="tooltip" title="Back" data-bs-container="body" data-inbox="back">
                                    <i class="fas fa-arrow-left fs-3"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Favorite" data-bs-container="body">
                                    <i class="fas fa-heart fs-6"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Reply" data-bs-container="body">
                                    <i class="fas fa-reply fs-6"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Delete" data-bs-container="body">
                                    <i class="fas fa-trash fs-6"></i>
                                </a>
                            </div>
                            <!--end::Left-->
                            <!--begin::Right-->
                            <div class="d-flex align-items-center justify-content-end text-end my-2">
                                <span class="text-muted fw-bold me-2">1/247</span>
                                <!--begin::Arrow Buttons-->
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Previous page" data-bs-container="body">
                                    <i class="fas fa-chevron-left fs-6"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Next page" data-bs-container="body">
                                    <i class="fas fa-chevron-right fs-6"></i>
                                </a>
                                <!--end::Arrow Buttons-->
                            </div>
                            <!--end::Right-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body">
                            <!--begin::Header-->
                            <div class="d-flex flex-stack flex-wrap py-5">
                                <!--begin::Title-->
                                <div class="d-flex align-items-center flex-wrap me-2 py-2">
                                    <div class="fw-bold fs-2 me-3">Trip Reminder. Thank you for flying with us!</div>
                                    <div class="my-2">
                                        <span class="badge badge-light-primary fw-bold rounded me-2">inbox</span>
                                        <span class="badge badge-light-danger fw-bold rounded">important</span>
                                    </div>
                                </div>
                                <!--end::Title-->
                                <!--begin::Toolbar-->
                                <div class="d-flex py-2">
                                    <span class="btn btn-active-light-primary btn-sm btn-icon" data-bs-dismiss="modal">
                                        <i class="fas fa-print"></i>
                                    </span>
                                </div>
                                <!--end::Toolbar-->
                            </div>
                            <!--end::Header-->
                            <!--begin::Message-->
                            <div class="mb-3">
                                <div class="d-flex py-6 flex-column flex-md-row flex-lg-column flex-xxl-row justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-40px me-4">
                                            <span class="symbol-label bg-light">
                                                <img src="frontend/assets/media/svg/avatars/015-boy-6.svg" class="h-75 align-self-end" alt="" />
                                            </span>
                                        </div>
                                        <!--end::Symbol-->
                                        <div class="d-flex flex-column flex-grow-1 flex-wrap me-2">
                                            <div class="d-flex align-items-center flex-wrap">
                                                <a href="#" class="fs-6 fw-bolder text-gray-800 text-hover-primary me-2">Chris Muller</a>
                                                <div class="fw-bold fs-7 text-muted">
                                                <span class="bullet bullet-dot bg-primary w-6px h-6px me-2"></span>1 Day ago</div>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <span class="fw-bold text-muted cursor-pointer" data-bs-toggle="dropdown">to me
                                                <i class="flaticon2-down fs-9 ms-1 text-gray-600"></i></span>
                                                <div class="dropdown-menu dropdown-menu-start p-5">
                                                    <table>
                                                        <tr>
                                                            <td class="text-muted min-w-75px py-2">From</td>
                                                            <td>Mark Andre</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted py-2">Date:</td>
                                                            <td>Jul 30, 2019, 11:27 PM</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted py-2">Subject:</td>
                                                            <td>Trip Reminder. Thank you for flying with us!</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted py-2">Reply to:</td>
                                                            <td>mark.andre@gmail.com</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex my-2 my-xxl-0 align-items-md-center align-items-lg-start align-items-xxl-center flex-column flex-md-row flex-lg-column flex-xxl-row">
                                        <div class="fw-bold text-muted mx-2">Jul 15, 2019, 11:19AM</div>
                                        <div class="d-flex align-items-center flex-wrap flex-xxl-nowrap" data-inbox="toolbar">
                                            <span class="btn btn-active-light-primary btn-sm btn-icon me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Star">
                                                <i class="fas fa-star fs-6"></i>
                                            </span>
                                            <span class="btn btn-active-light-primary btn-sm btn-icon me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as important">
                                                <i class="fas fa-bookmark fs-6"></i>
                                            </span>
                                            <span class="btn btn-active-light-primary btn-sm btn-icon me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Reply">
                                                <i class="fas fa-reply fs-6"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="py-3">
                                    <p>Hi Bob,</p>
                                    <p>With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part of any article is the title.Without a compelleing title, your reader won't even get to the first sentence.After the title, however, the first few sentences of your article are certainly the most important part.</p>
                                    <p>Jornalists call this critical, introductory section the "Lede," and when bridge properly executed, it's the that carries your reader from an headine try at attention-grabbing to the body of your blog post, if you want to get it right on of these 10 clever ways to omen your next blog posr with a bang</p>
                                    <p>Best regards,</p>
                                    <p>Jason Muller</p>
                                </div>
                            </div>
                            <!--end::Message-->
                            <!--begin::Reply-->
                            <div class="card shadow" id="kt_inbox_reply">
                                <div class="card-body p-0">
                                    <!--begin::Form-->
                                    <form id="kt_inbox_reply_form">
                                        <!--begin::Body-->
                                        <div class="d-block">
                                            <!--begin::To-->
                                            <div class="d-flex align-items-center border-bottom inbox-to px-8 min-h-50px">
                                                <div class="text-gray-600 w-75px">To:</div>
                                                <div class="d-flex align-items-center flex-grow-1">
                                                    <input type="text" class="form-control border-0" name="compose_to" value="Chris Muller, Lina Nilson" />
                                                </div>
                                                <div class="ms-2">
                                                    <span class="text-muted fw-bold cursor-pointer text-hover-primary me-2" data-inbox="cc-show">Cc</span>
                                                    <span class="text-muted fw-bold cursor-pointer text-hover-primary" data-inbox="bcc-show">Bcc</span>
                                                </div>
                                            </div>
                                            <!--end::To-->
                                            <!--begin::CC-->
                                            <div class="d-none align-items-center border-bottom inbox-to-cc ps-8 pe-5 min-h-50px">
                                                <div class="text-gray-600 w-75px">Cc:</div>
                                                <div class="flex-grow-1">
                                                    <input type="text" class="form-control border-0" name="compose_cc" value="" />
                                                </div>
                                                <span class="btn btn-active-light-primary btn-sm btn-icon" data-inbox="cc-hide">
                                                    <i class="fas fa-times"></i>
                                                </span>
                                            </div>
                                            <!--end::CC-->
                                            <!--begin::BCC-->
                                            <div class="d-none align-items-center border-bottom inbox-to-bcc ps-8 pe-5 min-h-50px">
                                                <div class="text-gray-600 w-75px">Bcc:</div>
                                                <div class="flex-grow-1">
                                                    <input type="text" class="form-control border-0" name="compose_bcc" value="" />
                                                </div>
                                                <span class="btn btn-active-light-primary btn-sm btn-icon" data-inbox="bcc-hide">
                                                    <i class="fas fa-times"></i>
                                                </span>
                                            </div>
                                            <!--end::BCC-->
                                            <!--begin::Subject-->
                                            <div class="border-bottom">
                                                <input class="form-control border-0 px-8 min-h-45px" name="compose_subject" placeholder="Subject" />
                                            </div>
                                            <!--end::Subject-->
                                            <!--begin::Message-->
                                            <div id="kt_inbox_reply_editor" class="border-0" style="height: 250px"></div>
                                            <!--end::Message-->
                                            <!--begin::Attachments-->
                                            <div class="dropzone dropzone-multi px-8 py-4" id="kt_inbox_reply_attachments">
                                                <div class="dropzone-items">
                                                    <div class="dropzone-item" style="display:none">
                                                        <div class="dropzone-file">
                                                            <div class="dropzone-filename" title="some_image_file_name.jpg">
                                                                <span data-dz-name="">some_image_file_name.jpg</span>
                                                                <strong>(
                                                                <span data-dz-size="">340kb</span>)</strong>
                                                            </div>
                                                            <div class="dropzone-error" data-dz-errormessage=""></div>
                                                        </div>
                                                        <div class="dropzone-progress">
                                                            <div class="progress">
                                                                <div class="progress-bar bg-primary" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" data-dz-uploadprogress=""></div>
                                                            </div>
                                                        </div>
                                                        <div class="dropzone-toolbar">
                                                            <span class="dropzone-delete" data-dz-remove="">
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Attachments-->
                                        </div>
                                        <!--end::Body-->
                                        <!--begin::Footer-->
                                        <div class="d-flex flex-stack py-5 ps-8 pe-5 border-top">
                                            <!--begin::Actions-->
                                            <div class="d-flex align-items-center me-3">
                                                <!--begin::Send-->
                                                <button class="btn btn-primary fw-bold px-6 me-4">Send</button>
                                                <!--end::Send-->
                                                <!--begin::Other-->
                                                <button class="btn btn-icon btn-active-light-primary me-2" id="kt_inbox_reply_attachments_select">
                                                    <i class="fas fa-paperclip fs-4"></i>
                                                </button>
                                                <button class="btn btn-icon btn-active-light-primary">
                                                    <i class="fas fa-map-marker-alt fs-4"></i>
                                                </button>
                                                <!--end::Other-->
                                            </div>
                                            <!--end::Actions-->
                                            <!--begin::Toolbar-->
                                            <div class="d-flex align-items-center">
                                                <span class="btn btn-icon btn-active-light-primary me-2" data-bs-toggle="tooltip" title="More actions">
                                                    <i class="fas fa-wrench fs-4"></i>
                                                </span>
                                                <span class="btn btn-icon btn-active-light-primary" data-inbox="dismiss" data-bs-toggle="tooltip" title="Dismiss reply">
                                                    <i class="fas fa-trash fs-6"></i>
                                                </span>
                                            </div>
                                            <!--end::Toolbar-->
                                        </div>
                                        <!--end::Footer-->
                                    </form>
                                    <!--end::Form-->
                                </div>
                            </div>
                            <!--end::Reply-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::View-->
            </div>
            <!--end::Inbox-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Main-->
</template>

<script>
export default {
    methods:{
      goToPath(path){
        this.$router.push(path)
      }
    }
}
</script>

<style>

</style>