
<template>
    <div>
      <!-- Aquí puedes mostrar un mensaje de confirmación de logout si lo deseas -->
    </div>
  </template>
  
  <script >
  import { logout } from '../../services/auth/authService'; // Importa la función de logout
  import router  from '@/router';
 
  export default {
	
	name:'Login',
  props: ['UserData'], // Declara la prop actualizarVariableApp

  methods: {
    update() {
		this.$emit('update:UserData', 'false'); // Emitir evento para actualizar la variableApp en App.vue
    },
  },
  mounted(){
    logout();
		this.update();
    router.push('/login');
	}
};
  </script>