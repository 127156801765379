<script setup>
import { ref, onMounted} from 'vue'
import GetData from '@/services/users/userService';
import GetDataShow from '@/services/users/userServiceShow';
const items = ref()
const information = ref()
const RandomPass = ref("")
onMounted(() => {
    GetData("Active").then((response) => {
                items.value = response.data
			});
})
function ShowDataUser(id) {     
    GetDataShow(id).then((response) => {
                information.value = response
				console.log(information.value);
			});
}
function DesactivateUser(id) {     
    // Desactivate(id).then((response) => {
	// 			console.log("Desactivado");
	// 		});
    console.log("Desactivado")
}
function generatePasswordRand() {
    var pass = "";
    for (var i=0; i < 8; i++){
        pass += String.fromCharCode((Math.floor((Math.random() * 100)) % 94) + 33);
    }
    console.log(pass)
    RandomPass.value = pass
}  
</script>
<template>
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Usuarios</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Listado de usuarios</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
        </div>
    </div>
    <!--end::toolbar-->
<div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
    <!--begin::Container-->
    <div class="container">
        <!--begin::Layout-->
        <div class="flex-row-fluid">
            <!--begin::Section-->
            <!--begin::Table Widget 4-->
            <div class="card">
                <!--begin::Header-->
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark">Listado de Usuarios</span>
                    </h3>
                    <div class="card-toolbar">
                        <a href="javascript:;" @click="goToPath('/users/create')" class="btn btn-primary fw-bolder fs-7">Nuevo Usuario</a>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body py-0">
                    <!--begin::Table-->
                    <div class="table-responsive">
                        <table class="table align-middle border-gray-100" id="kt_advance_table_widget_4">
                            <thead>
                                <tr class="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                                    <td class="ps-0 w-30px py-5">
                                        <div class="form-check form-check-custom form-check-sm form-check-solid me-2">
                                            <input class="form-check-input" type="checkbox" value="1" />
                                        </div>
                                    </td>
                                    <td class="ps-0 min-w-25px py-5">Id</td>
                                    <td class="min-w-75px py-5">Nombre completo</td>
                                    <td class="min-w-75px py-5">Correo Eléctronico</td>
                                    <td class="min-w-75px py-5">Correo Eléctronico de seguridad</td>
                                    <td class="min-w-75px py-5">Telefonos</td>
                                    <td class="min-w-75px py-5">Pais</td>
                                    <td class="min-w-300px text-center py-5">Opciones</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :class="{ active: index==0 }" data-action="list" v-for="(item, index) in items">
                                    <td class="ps-0 py-6">
                                        <div class="form-check form-check-custom form-check-sm form-check-solid me-2">
                                            <input class="form-check-input" type="checkbox" value="1" />
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.id }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.fullName }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.email }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.emailBackup }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.phones }}</span>
                                    </td>
                                    <td>
                                        <span class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ item.Country }}</span>
                                    </td>
                                    <td class="pe-0 text-end">
                                        <a href="javascript:;" @click="ShowDataUser(item.id)" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx-3"  title="Ver usuario" data-bs-container="body" data-bs-toggle="modal" data-bs-target="#kt_modal_show">
                                            <!--begin::Svg Icon | path: icons/stockholm/Communication/Write.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-user"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a href="#" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm" title="Cambiar contraseña" data-bs-container="body" data-bs-toggle="modal" data-bs-target="#kt_modal_changepassword">
                                            <!--begin::Svg Icon | path: icons/stockholm/General/Trash.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fa fa-unlock-alt"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a href="javascript:;" @click="editUser('/users/edit/' ,item.id)" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx-3" data-bs-toggle="tooltip" title="Editar usuario" data-bs-container="body">
                                            <!--begin::Svg Icon | path: icons/stockholm/Communication/Write.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-edit"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a href="#" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm" title="Eliminar usuario" data-bs-container="body" data-bs-toggle="modal" data-bs-target="#kt_modal_delete">
                                            <!--begin::Svg Icon | path: icons/stockholm/General/Trash.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-trash"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a href="#" class="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx-3" title="Desactivar usuario" data-bs-container="body" data-bs-toggle="modal" data-bs-target="#kt_modal_desactivate">
                                            <!--begin::Svg Icon | path: icons/stockholm/General/Trash.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <i class="fas fa-user-slash"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
            <!--end::Table Widget 4-->
            <!--end::Section-->
        </div>
        <!--end::Layout-->
    </div>
    <!--end::Container-->
    <div class="modal bg-white fade" tabindex="-1" id="kt_modal_show">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content shadow-none">
                <div class="modal-body">
                    <div class="card shadow-none w-md-1000px w-xxl-1000px">
                        <!--begin::Card Body-->
                        <div class="card-body bg-light px-12 py-10">
                            <h3 class="fw-bolder fs-1 mb-1">
                                <a v-if="information" class="text-gray-800">{{information.fullName}}</a>
                            </h3>
                            <!--begin::Info-->
                            <table class="table table-borderless align-middle fw-bold">
                                <tr>
                                    <td class="text-gray-600 ps-0">Dirección:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.direction}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Télefono:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.phones}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Nombre de contacto:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.displayName}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Correo eléctronico:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.email}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Correo eléctronico de recuperacion:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.emailBackup}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Creado el:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.createdAt}}</td>
                                </tr>
                                <tr>
                                    <td class="text-gray-600 ps-0">Compañia:</td>
                                    <td v-if="information" class="text-dark pe-0">{{information.companyId}}</td>
                                </tr>
                            </table>
                            <!--end::Info-->
                        </div>
                        <!--end::Card Body-->
                    </div>
                    <!--begin::Sidebar-->
                    <div id="kt_sidebar" class="sidebar bg-info" data-kt-drawer="true" data-kt-drawer-name="sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '350px': '300px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_sidebar_toggler">
                        <!--begin::Sidebar Content-->
                        <div class="d-flex flex-column sidebar-body">
                            <!--begin::Sidebar Content-->
                            <div id="kt_sidebar_content" class="py-10 px-2 px-lg-8">
                                <div class="hover-scroll-y me-lg-n5 pe-lg-4" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-offset="0px" data-kt-scroll-wrappers="#kt_sidebar_content">
                                    <!--begin::Card-->
                                    <div class="card bg-info">
                                        <!--begin::Body-->
                                        <div class="card-body px-0">
                                            <div class="pt-0">
                                                <!--begin::Chart-->
                                                <div class="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center" style="background-image:url('frontend/assets/media/svg/illustrations/bg-2.svg')">
                                                    <div class="position-absolute mb-7">
                                                        <div class="symbol symbol-circle symbol-100px overflow-hidden d-flex flex-center z-index-1">
                                                            <span class="symbol-label bg-warning align-items-end">
                                                                <img alt="Logo" src="frontend/assets/media/svg/avatars/016-boy-7.svg" class="mh-75px" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div id="kt_user_chart" style="height: 200px"></div>
                                                </div>
                                                <!--end::Chart-->
                                                <!--begin::Items-->
                                                <div class="pt-4">
                                                    <!--begin::Title-->
                                                    <div class="text-center pb-12">
                                                        <!--begin::Username-->
                                                        <h3 v-if="information" class="fw-bolder text-white fs-2 pb-4">{{information.displayName}}</h3>
                                                        <!--end::Username-->
                                                        <!--end::Action-->
                                                        <span v-if="information" class="fw-bolder fs-6 text-primary px-4 py-2 rounded bg-white bg-opacity-10">{{information.companyId}}</span>
                                                        <!--begin::Action-->
                                                    </div>
                                                    <!--end::Title-->
                                                    <!--begin::Row-->
                                                    <div class="row row-cols-2 px-xl-12 sidebar-toolbar">
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">38</span>
                                                                <span class="fw-bold">Ultima hora de conexion</span>
                                                            </a>
                                                        </div>
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">204</span>
                                                                <span class="fw-bold">Mensajes enviados</span>
                                                            </a>
                                                        </div>
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">76</span>
                                                                <span class="fw-bold">Tiempo online</span>
                                                            </a>
                                                        </div>
                                                        <div class="col p-3">
                                                            <a href="#" class="btn p-5 w-100 text-start btn-active-primary">
                                                                <span class="text-white fw-bolder fs-1 d-block pb-1">90%</span>
                                                                <span class="fw-bold">Perfil completado</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <!--end::Row-->
                                                </div>
                                                <!--end::Items-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Card-->
                                </div>
                            </div>
                            <!--end::Sidebar Content-->
                        </div>
                        <!--end::Sidebar Content-->
                    </div>
                    <!--end::Sidebar-->
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-light btn-bg-warning" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_delete">
        <br><br><br><br><br><br><br><br><br><br><br><br><br>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar usuario</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body">
                    <p>¿Estás seguro de que deseas eliminar el usuario?</p>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_desactivate">
        <br><br><br><br><br><br><br><br><br><br><br><br><br>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Desactivar usuario</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body">
                    <p>¿Estás seguro de que deseas desactivar el usuario?</p>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" @click="DesactivateUser()"  class="btn btn-primary" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_changepassword">
        <br><br><br><br><br><br><br><br><br>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Cambiar contraseña</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Nueva contraseña</label>
                            <input type="password" name="password" class="form-control" id="Password" placeholder="Password" v-model="RandomPass">
                        </div>
                        <a href="javascript:;" @click="generatePasswordRand()" class="btr btn-active">Sugerencia</a>
                        <br>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Confirmar contraseña</label>
                            <input type="password" class="form-control" id="ConfirmPassword" placeholder="Confirm Password" v-model="RandomPass">
                        </div>
                        <br>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--end::Content-->
</template>



<script>


export default {
	name:'ListUsers',
    methods: {
        goToPath(path){
            this.$router.push(path)
        }, 
        editUser(path, id){
            this.$router.push({
            name: "usersedit",
            params:{
                id: id,
            }
            });
        },
        }
    };
    
</script>

<style>

</style>