<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { loadScript } from '@/helpers/helpers';
import GetData from '@/services/messages/messageService'
onMounted(() => {
    loadScript('./frontend/assets/js/components/blockui.js')
            .then(() => {
                
            })
            .catch(() => {
                console.log("No cargado")
            });
    RefreshData()
    })
// import  loadScript  from 'load-script';
    const items = ref([{ Subject:'Tarea', Message:'Buenos dias señor pedro', From:'Oscar Isaac', Time:'Ahora' }, { Subject:'Entrega', Message:'Necesito la entrega inmediata', From:'Fabricio Rodriguez', Time:'Hace una hora' }])
    const message = ref()
    const LoadData = ref(true)
    const carga = ref(0)
  let intervalId
   function RefreshData() {
    LoadData.value = true
    GetData("Important").then((response) => {
        if(response.data.length != 0){
            carga.value = 1
        }
        console.log(response.data)
        message.value = response.data
        LoadData.value = false    
	});
  }
  
</script>
<template>
<!--begin::Main-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Correos</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Importantes</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Info-->
            <!--begin::Nav-->
            <!-- <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
                <a href="apps/chat.html" class="btn btn-active-accent fw-bolder">Chat</a>
                <a href="apps/inbox.html" class="btn btn-active-accent active fw-bolder ms-3">Inbox</a>
                <a href="apps/shop/shop-1.html" class="btn btn-active-accent fw-bolder ms-3">Shop 1</a>
                <a href="apps/shop/shop-2.html" class="btn btn-active-accent fw-bolder ms-3">Shop 2</a>
                <a href="apps/shop/product.html" class="btn btn-active-accent fw-bolder ms-3">Shop Product</a>
            </div> -->
            <!--end::Nav-->
        </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Inbox-->
            <div class="d-flex flex-column flex-lg-row">
                <!--begin::Aside-->
                <div class="flex-lg-row-auto w-lg-275px w-xxl-375px" id="kt_inbox_aside">
                    <!--begin::Item-->
                    <div class="card">
                        <!--begin::Head-->
                        <div class="card-header card-header-stretch">
                            <ul class="nav nav-stretch nav-line-tabs border-bottom-0">
                                <li class="nav-item">
                                    <a href="javascript:;" @click="goToPath('/')" class="nav-link justify-content-center w-60px" >
                                        <span class="nav-icon m-0">
                                            <!--begin::Svg Icon | path: icons/stockholm/Communication/Mail-heart.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M13.8,4 C13.1562,4 12.4033,4.72985286 12,5.2 C11.5967,4.72985286 10.8438,4 10.2,4 C9.0604,4 8.4,4.88887193 8.4,6.02016349 C8.4,7.27338783 9.6,8.6 12,10 C14.4,8.6 15.6,7.3 15.6,6.1 C15.6,4.96870845 14.9396,4 13.8,4 Z" fill="#000000" opacity="0.3" />
                                                    <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="#000000" />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:;" @click="goToPath('/messages/out')" class="nav-link justify-content-center w-60px " >
                                        <span class="nav-icon m-0">
                                            <!--begin::Svg Icon | path: icons/stockholm/Communication/Send.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <path d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z" fill="#000000" />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:;" @click="goToPath('/messages/delete')" class="nav-link justify-content-center w-60px ms-0" data-bs-toggle="tab">
                                        <span class="nav-icon m-0">
                                            <!--begin::Svg Icon | path: icons/stockholm/General/Trash.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero" />
                                                        <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:;" @click="goToPath('/messages/important')" class="nav-link justify-content-center w-60px ms-0 active" data-bs-toggle="tab">
                                        <span class="nav-icon m-0">
                                            <!--begin::Svg Icon | path: icons/stockholm/Design/Select.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3" />
                                                    <path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000" />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!--begin::Head-->
                        <!--begin::Body-->
                        <div class="card-body px-0 pb-0">
                            <!--begin::Title-->
                            <div class="d-flex flex-stack px-9 mt-3 mb-10">
                                <div class="d-flex align-items-center">
                                    <span class="fs-1 fw-bolder me-3">Recordatorios</span>
                                    <span class="badge badge badge-light text-muted fs-7 fw-bolder rounded py-2 px-3">7</span>
                                </div>
                                <div class="btn btn-light-primary fs-6 fw-bolder py-2 px-4" data-bs-toggle="modal" data-bs-target="#kt_inbox_compose">Redactar</div>
                            </div>
                            <!--end::Title-->
                            <!--begin::Inbox Aside-->
                            <div class="overflow-auto">
                                <div data-action="list" class="mh-300px mh-lg-unset">
                                    <!--begin::Item-->
                                    <div class="bg-state-light  px-9 py-8 d-flex" :class="{ active: index==0 }" data-action="list" v-for="(item, index) in items">
                                        <div class="w-15px h-15px me-3">
                                            <span class="bullet bullet-dot w-6px h-6px bg-primary"></span>
                                        </div>
                                        <div class="d-flex flex-row-fluid flex-column">
                                            <div class="fs-6 fw-bolder text-dark mb-3">{{ item.Subject }}</div>
                                            <div class="fw-bold fs-7 text-gray-600 mb-3">{{ item.Message }}</div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-muted fw-bolder">{{ item.From }}</div>
                                                <div class="text-gray-400 fs-7 fw-bolder text-end">{{ item.Time }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Item-->
                                </div>
                            </div>
                            <!--end::Inbox Aside-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Aside-->
                <!--begin::List-->
                <div class="flex-lg-row-fluid ms-lg-12 d-block" id="kt_inbox_list">
                    <!--begin::Card-->
                    <div class="card mt-10 mt-lg-0">
                        <!--begin::Header-->
                        <div class="card-header align-items-center card-px">
                            <!--begin::Toolbar-->
                            <div class="d-flex align-items-center">
                                <div class="form-check form-check-sm form-check-custom form-check-solid me-4 my-2" data-inbox="group-select">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                </div>
                                <div class="d-flex align-items-center me-1 my-2">
                                    <a href="javascript:;" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Favoritos">
                                        <i class="fas fa-heart fs-6"></i>
                                    </a>
                                    <a href="javascript:;" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Responder">
                                        <i class="fas fa-reply fs-6"></i>
                                    </a>
                                    <a href="javascript:;" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Borrar">
                                        <i class="fas fa-trash fs-6"></i>
                                    </a>
                                </div>
                            </div>
                            <!--end::Toolbar-->
                            <!--begin::Pagination-->
                            <div class="d-flex align-items-center justify-content-sm-end text-end my-2">
                                <!--begin::Per Page Dropdown-->
                                <div class="d-flex align-items-center me-2">
                                    <span class="text-muted fw-bold me-2">1/247</span>
                                </div>
                                <!--end::Per Page Dropdown-->
                                <!--begin::Arrow Buttons-->
                                <a href="javascript:;" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Página Anterior" data-bs-container="body">
                                    <i class="fas fa-chevron-left fs-6"></i>
                                </a>
                                <a href="javascript:;" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Siguiente Página" data-bs-container="body">
                                    <i class="fas fa-chevron-right fs-6"></i>
                                </a>
                                <a @click="RefreshData()" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Actualizar" data-bs-container="body">
                                    <i style="font-size: 18px;" class="fa fa-redo-alt"></i>
                                </a>
                                <!--end::Arrow Buttons-->
                            </div>
                            <!--end::Pagination-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body px-0 overflow-auto" v-bind:class="{ 'overlay overlay-block': LoadData }">
                            <!--begin::Items-->
                            <div class="min-w-700px min-h-300px" data-inbox="list" v-if="message && !LoadData">
                                <!--begin::Item-->
                                <div class="d-flex align-items-start bg-hover-light card-px py-3" @click="goToPath('/messages/reply')" :class="{ active: index==0 }" data-action="list" v-for="(item, index) in message" v-if="carga != 0">
                                    <!--begin::Toolbar-->
                                    <div class="d-flex align-items-center">
                                        <!--begin::Actions-->
                                        <div class="d-flex align-items-center me-3" data-inbox="actions">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input class="form-check-input" type="checkbox" value="1" />
                                            </div>
                                            <a href="javascript:;" class="btn btn-icon btn-sm text-hover-warning active" data-bs-toggle="tooltip" data-bs-placement="right" title="Star">
                                                <i class="fas fa-star text-muted"></i>
                                            </a>
                                            <a href="javascript:;" class="btn btn-icon btn-sm text-hover-warning" data-bs-toggle="tooltip" data-bs-placement="right" title="Mark as important">
                                                <i class="fas fa-bookmark text-muted"></i>
                                            </a>
                                        </div>
                                        <!--end::Actions-->
                                        <!--begin::Author-->
                                        <div class="d-flex align-items-center flex-wrap w-xxl-200px me-3" data-bs-toggle="view">
                                            <!--begin::Symbol-->
                                            <div class="symbol symbol-40px me-4">
                                                <span class="symbol-label bg-light">
                                                    <img src="frontend/assets/media/svg/avatars/035-boy-15.svg" class="h-75 align-self-end" alt="" />
                                                </span>
                                            </div>
                                            <!--end::Symbol-->
                                            <a class="fw-bold text-gray-800 text-hover-primary">{{ item.userFromName }}</a>
                                        </div>
                                        <!--end::Author-->
                                    </div>
                                    
                                    <!--end::Toolbar-->
                                    <!--begin::Info-->
                                    <div class="flex-grow-1 mt-2 me-2" data-bs-toggle="view">
                                        <div>
                                            <span class="fw-bolder fs-6 me-2">{{ item.subject }}</span>
                                            <span class="text-muted">{{ item.message }}</span>
                                        </div>
                                        <div class="mt-2">
                                            <span class="badge badge-light-primary me-1">inbox</span>
                                            <span class="badge badge-light-danger">task</span>
                                        </div>
                                    </div>
                                    <!--end::Info-->
                                    <!--begin::Datetime-->
                                    <div class="mt-2 me-3 fw-bolder w-50px text-end" data-bs-toggle="view">{{ item.time }}</div>
                                    <!--end::Datetime-->
                                </div>
                                <!--end::Item-->
                                <div  align = "center" v-else><h2>No existen correos para mostrar</h2></div>
                            </div>
                            <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="LoadData">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <!--end::Items-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::List-->
            </div>
            <!--end::Inbox-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Main-->
</template>

<script>
import { ref } from 'vue'

 
export default {
    name: 'Home',
    methods:{
      goToPath(path){
        this.$router.push(path)
      }
  }
}

</script>

<style>

</style>