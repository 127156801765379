import apiService from '../apiService';
import Swal from 'sweetalert2'
import router  from '@/router';
import sessionService from '@/services/data/sessionService';

export default function SingIn(Email: string, Password: string): Promise<any> {

  return apiService.post('/api/auth/login', {
        email: Email,
        password:  Password,
    })
    .then((response) => {
        console.log(response)
        // Para guardar el token
        sessionService.Store('token', response.data.access_token);
        sessionService.Store('expires', response.data.expires_in);
        sessionService.Store('login', "true");
        Swal.close();
        router.push('/');
        return true;
    })
    .catch((error) => {
       
        // Manejo de errores
        Swal.fire({
            icon: 'error',
            title: 'Error - '+error.response.data.error,
            text: error.response.data.message,
            confirmButtonText:"Aceptar"
        })
        return false;
    });
}
export function logout(): void {
    // Eliminar las variables de sesión
    sessionService.Delete('token');
    sessionService.Delete('expires');
    sessionService.Delete('login');
    
  }