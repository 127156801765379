<script setup>
   import { reactive, computed,ref } from 'vue'

   import Swal from 'sweetalert2'
   import axios from 'axios'
   import VueAxios from 'vue-axios'
   import SingIn from '../../services/auth/authService';
   import { loadScript } from '@/helpers/helpers';
  

    const Email = ref("");
	const Password = ref("");
	function login() {
		if(Email.value=="" || Email.value==" ")
		{
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Para continuar introduzca su correo...',
				confirmButtonText:"Aceptar"
			})
		}
		else if(Password.value=="" || Email.value==" ")
		{
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Para continuar introduzca su contraseña...',
				confirmButtonText:"Aceptar"
			})
		}
		else{
			Swal.fire({
				title: '¡Porfavor espere !',
				html: 'Cargando...',
				allowOutsideClick: false,
				showConfirmButton: false,
				willOpen: () => {
					Swal.showLoading();
				},
			});
			
			SingIn(Email.value,Password.value) .then((response) => {
				
				if(response==true){
					setTimeout(() => {
					loadScript('./frontend/assets/js/custom/menu.js')
					.then(() => {
					console.log("Cargado")
					})
					.catch(() => {
						console.log("No cargado")
					});
				}, 3000);
					
					
				}
			});
			
		}
		
   }
   function terms(){
	Swal.fire({
	title: 'Terminos y condiciones',
	text: 'Todo el texto',
	icon: 'info',
	iconHtml: '!',
	confirmButtonText: 'Acepto',
	cancelButtonText: 'Rechazar',
	showCancelButton: true,
	showCloseButton: true
	})
   }
   function plans(){
	Swal.fire({
	title: 'Planes',
	text: 'Todo el texto',
	icon: 'info',
	iconHtml: '!',
	})
   }
   function contact(){
	Swal.fire({
	title: 'Contacto',
	text: 'Todo el texto',
	icon: 'info',
	iconHtml: '!',
	})
   }
   
   defineEmits(['loadLogin']);
</script>
<template>
<!--begin::Main-->
<div class="d-flex flex-column flex-root" style="height: 100%!important;">
			<!--begin::Login-->
			<div class="d-flex flex-column flex-lg-row flex-column-fluid bg-primary " id="kt_login">
				<!--begin::Aside-->
				<div class="d-flex flex-column flex-lg-row-auto  w-lg-600px pt-15 pt-lg-0" style="background-color: white;">
					<div class="Loginleftpanel">
                        <!--begin::Aside Top-->
                        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
                            <!--begin::Aside Logo-->
                            <a href="index.html" class="mb-6 logologin">
                                <img alt="Logo" src="frontend/assets/media/logos/Logo4.png" class="h-150px" />
                            </a>
                            <!--end::Aside Logo-->
                            <!--begin::Aside Subtitle-->
                            <!-- <h3 class="fw-bolder fs-2x text-dark lh-lg">Administrador de Correo
                            <br />Web</h3> -->
                            <!--end::Aside Subtitle-->
                        </div>
                        <!--end::Aside Top-->
                        <!--begin::Aside Bottom-->
                        <div class="d-flex flex-row-fluid bgi-size-contain bgi-no-repeat bgi-position-y-bottom bgi-position-x-center min-h-250px " style="background-image: url(frontend/assets/media/misc/loginimage.png);max-height: 100px;"></div>
                        <!--end::Aside Bottom-->
                    </div>
				</div>
				<!--begin::Aside-->
				<!--begin::Content-->
				<div class="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 px-10 p-lg-7 mx-auto mw-450px w-100">
					<!--begin::Wrapper-->
					<div class="d-flex flex-column-fluid flex-center py-10">
						<!--begin::Signin Form-->
						<form class="form w-100" novalidate="novalidate" id="kt_login_signin_form" data-after-login-url="index.html">
							<!--begin::Title-->
							<div class="pb-5 pb-lg-15">
								<h3 class="fw-bolder  display-6 text-white">Bienvendio a GuateMail.gt</h3>
								<div class="text-dark fw-bold fs-3">¿No tienes cuenta?
								<a href="#" class="text-white fw-bolder" id="kt_login_signin_form_singup_button">Crear cuenta</a></div>
							</div>
							<!--begin::Title-->
							<!--begin::Form group-->
							<div class="fv-row mb-10">
								<label class="form-label fs-6 fw-bolder text-white">Correo</label>
								<input class="form-control form-control-lg form-control-solid" type="text" name="username" v-model="Email" autocomplete="off" />
							</div>
							<!--end::Form group-->
							<!--begin::Form group-->
							<div class="fv-row mb-10">
								<div class="d-flex justify-content-between mt-n5">
									<label class="form-label fs-6 fw-bolder text-white pt-5">Contraseña</label>
									<a href="#" class="text-white fs-6 fw-bolder text-hover-secondary pt-5" id="kt_login_signin_form_password_reset_button">¿Olvido su contraseña?</a>
								</div>
								<input class="form-control form-control-lg form-control-solid" type="password" v-on:keyup.enter="login()" name="password" v-model="Password" autocomplete="off" />
							</div>
							<!--end::Form group-->
							<!--begin::Action-->
							<div class="pb-lg-0 pb-5">
								
								<button type="button" class="center btn btn-light-primary fw-bolder px-8 py-4 my-3 fs-6" v-on:click="login()">
								<img src="frontend/assets/media/svg/files/folder-document.svg" class="w-20px h-20px me-3" alt="" />Iniciar sesión</button>
							</div>
							<!--end::Action-->
						</form>
						<!--end::Signin Form-->
						<!--begin::Signup Form-->
						<form class="form d-none w-100" novalidate="novalidate" id="kt_login_signup_form">
							<!--begin::Title-->
							<div class="pb-5 pb-lg-15">
								<h3 class="fw-bolder text-white display-6">Sign Up</h3>
								<p class="text-white fw-bold fs-3">Enter your details to create your account</p>
							</div>
							<!--end::Title-->
							<!--begin::Form group-->
							<div class="fv-row mb-5">
								<label class="form-label fs-6 fw-bolder text-dark pt-5">Name</label>
								<input class="form-control form-control-lg form-control-solid" type="text" placeholder="" name="fullname" autocomplete="off" />
							</div>
							<!--end::Form group-->
							<!--begin::Form group-->
							<div class="fv-row mb-5">
								<label class="form-label fs-6 fw-bolder text-dark pt-5">Email</label>
								<input class="form-control form-control-lg form-control-solid" type="email" placeholder="" name="email" autocomplete="off" />
							</div>
							<!--end::Form group-->
							<!--begin::Form group-->
							<div class="fv-row mb-5">
								<label class="form-label fs-6 fw-bolder text-dark pt-5">Password</label>
								<input class="form-control form-control-lg form-control-solid" type="password" placeholder="" name="password" autocomplete="off" />
							</div>
							<!--end::Form group-->
							<!--begin::Form group-->
							<div class="fv-row mb-10">
								<label class="form-label fs-6 fw-bolder text-dark pt-5">Confirm Password</label>
								<input class="form-control form-control-lg form-control-solid" type="password" placeholder="" name="cpassword" autocomplete="off" />
							</div>
							<!--end::Form group-->
							<!--begin::Form group-->
							<div class="fv-row mb-10">
								<div class="form-check form-check-custom form-check-solid mb-5">
									<input class="form-check-input" type="checkbox" id="kt_login_toc_agree" value="1" />
									<label class="form-check-label fw-bold text-gray-600" for="kt_login_toc_agree">I Agree the
									<a href="#" class="ms-1">terms and conditions</a>.</label>
								</div>
							</div>
							<!--end::Form group-->
							<!--begin::Form group-->
							<div class="d-flex flex-wrap pb-lg-0 pb-5">
								<button type="button" id="kt_login_signup_form_submit_button" class="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4">Submit</button>
								<button type="button" id="kt_login_signup_form_cancel_button" class="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3">Cancel</button>
							</div>
							<!--end::Form group-->
						</form>
						<!--end::Signup Form-->
						<!--begin::Password Reset Form-->
						<form class="form d-none w-100" novalidate="novalidate" id="kt_login_password_reset_form">
							<!--begin::Title-->
							<div class="pb-5 pb-lg-10">
								<h3 class="fw-bolder text-white display-6">Forgotten Password ?</h3>
								<p class="text-muted fw-bold fs-3">Enter your email to reset your password</p>
							</div>
							<!--end::Title-->
							<!--begin::Form group-->
							<div class="fv-row mb-10">
								<label class="form-label fs-6 fw-bolder text-white pt-5">Email</label>
								<input class="form-control form-control-lg form-control-solid" type="email" placeholder="" name="email" autocomplete="off" />
							</div>
							<!--end::Form group-->
							<!--begin::Form group-->
							<div class="d-flex flex-wrap pb-lg-0">
								<button type="button" id="kt_login_password_reset_form_submit_button" class="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4">Submit</button>
								<button type="button" id="kt_login_password_reset_form_cancel_button" class="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3">Cancel</button>
							</div>
							<!--end::Form group-->
						</form>
						<!--end::Password Reset Form-->
					</div>
					<!--end::Wrapper-->
					<!--begin::Footer-->
					<div class="d-flex justify-content-lg-start justify-content-center align-items-center py-7 py-lg-0">
						<a href="javascript:;" v-on:click="update()" class="text-white fw-bolder fs-4">Terminos y condiciones</a>
						<a href="javascript:;" v-on:click="plans()" class="text-white ms-10 fw-bolder fs-4">Planes</a>
						<a href="javascript:;" v-on:click="contact()" class="text-white ms-10 fw-bolder fs-4">Contáctanos</a>
					</div>
					<!--end::Footer-->
				</div>
				<!--end::Content-->
			</div>
			<!--end::Login-->
</div>
		
</template>



<script>
import sessionService from '@/services/data/sessionService';
export default {
	
	name:'Login',
	props: {
		UserData: String,
		setUserData: Function,
	},
  methods: {
    update() {
		sessionService.Store('login','true');
    },
	mounted(){
		this.update();
	}
  },
 
};
</script>
<style>
.swal2-popup .swal2-icon{
	margin: auto!important;
}
.center{
	margin: 0;
  position: absolute;
 
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>