import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/pages/Login.vue'
import LogoutView from '../views/pages/Logout.vue'
import HomeView from '../views/Home/Home.vue'
import OutMessages from '../views/Home/OutMessages.vue'
import Favorites from '../views/More/Favorities.vue'
import DeleteMessages from '../views/Home/DeleteMessages.vue'
import Important from '../views/Home/Important.vue'
import Reply from '../views/Home/Reply.vue'
import Account from '../views/Profile/Account.vue'
import Settings from '../views/Profile/Settings.vue'
import Users from '../views/Users/ListUsers.vue'
import UsersCreate from '../views/Users/Create.vue'
import UsersEdit from '../views/Users/Edit.vue'
import Companies from '../views/Companies/ListCompanies.vue'
import CompaniesCreate from '../views/Companies/Create.vue'
import CompaniesEdit from '../views/Companies/Edit.vue'
import Social from '../views/Categories/Social.vue'
import Forum from '../views/Categories/Forum.vue'
import Spam from '../views/More/Spam.vue'
import Schedule from '../views/More/Schedule.vue'
import All from '../views/More/All.vue'
import Notes from '../views/More/Notes.vue'
import Notesview from '../views/More/noteview.vue'
import Createnote from '../views/More/createnote.vue'
import Createreminder from '../views/More/createreminder.vue'
import Notifications from '../views/pages/Notification.vue'
import History from '../views/pages/History.vue'
import Reminders from '../views/More/Reminders.vue'
import Calendar from '../views/More/Calendar.vue'
import Article from '../views/More/Article.vue'
import About from '../views/AboutView.vue'
import Contact from '../views/Contact.vue'
import sessionService from '@/services/data/sessionService';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutView, // componente Logout.vue para la página de logout
  },
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: HomeView
  },
  {
    path: '/messages/out',
    name: 'outmessages',
    meta: {
      requiresAuth: true
    },
    component: OutMessages
  },
  {
    path: '/messages/favorites',
    name: 'favorites',
    meta: {
      requiresAuth: true
    },
    component: Favorites
  },
  {
    path: '/article',
    name: 'article',
    meta: {
      requiresAuth: true
    },
    component: Article
  },
  {
    path: '/messages/delete',
    name: 'deletemessages',
    meta: {
      requiresAuth: true
    },
    component: DeleteMessages
  },
  {
    path: '/messages/important',
    name: 'importantmessages',
    meta: {
      requiresAuth: true
    },
    component: Important
  },
  {
    path: '/messages/reply',
    name: 'replymessages',
    meta: {
      requiresAuth: true
    },
    component: Reply
  },
  {
    path: '/messages/noteview',
    name: 'noteview',
    meta: {
      requiresAuth: true
    },
    component: Notesview
  },
  {
    path: '/messages/createnote',
    name: 'createnote',
    meta: {
      requiresAuth: true
    },
    component: Createnote
  },
  {
    path: '/messages/createreminder',
    name: 'createreminder',
    meta: {
      requiresAuth: true
    },
    component: Createreminder
  },
  {
    path: '/notifications',
    name: 'allnotifications',
    meta: {
      requiresAuth: true
    },
    component: Notifications
  },
  {
    path: '/history',
    name: 'History',
    meta: {
      requiresAuth: true
    },
    component: History
  },
  {
    path: '/profile/account',
    name: 'account',
    meta: {
      requiresAuth: true
    },
    component: Account
  },
  {
    path: '/profile/settings',
    name: 'settings',
    meta: {
      requiresAuth: true
    },
    component: Settings
  },
  {
    path: '/users/list',
    name: 'users',
    meta: {
      requiresAuth: true
    },
    component: Users
  },
  {
    path: '/users/create',
    name: 'userscreate',
    meta: {
      requiresAuth: true
    },
    component: UsersCreate
  },
  {
    path: '/users/edit/:id',
    name: 'usersedit',
    meta: {
      requiresAuth: true
    },
    component: UsersEdit
  },
  {
    path: '/companies/list',
    name: 'companies',
    meta: {
      requiresAuth: true
    },
    component: Companies
  },
  {
    path: '/companies/create',
    name: 'companiescreate',
    meta: {
      requiresAuth: true
    },
    component: CompaniesCreate
  },
  {
    path: '/companies/edit/:id',
    name: 'companiesedit',
    meta: {
      requiresAuth: true
    },
    component: CompaniesEdit
  },
  {
    path: '/social',
    name: 'social',
    meta: {
      requiresAuth: true
    },
    component: Social
  },
  {
    path: '/forum',
    name: 'forum',
    meta: {
      requiresAuth: true
    },
    component: Forum
  },
  {
    path: '/messages/spam',
    name: 'spam',
    meta: {
      requiresAuth: true
    },
    component: Spam
  },
  {
    path: '/messages/schedule',
    name: 'schedule',
    meta: {
      requiresAuth: true
    },
    component: Schedule
  },
  {
    path: '/messages/all',
    name: 'all',
    meta: {
      requiresAuth: true
    },
    component: All
  },
  {
    path: '/messages/notes',
    name: 'notes',
    meta: {
      requiresAuth: true
    },
    component: Notes
  },
  {
    path: '/messages/reminders',
    name: 'reminders',
    meta: {
      requiresAuth: true
    },
    component: Reminders
  },
  {
    path: '/more/calendar',
    name: 'calendar',
    meta: {
      requiresAuth: true
    },
    component: Calendar
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      requiresAuth: true
    },
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      requiresAuth: true
    },
    component: Contact
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
 
  if(to.name == 'login')
  {
    if (sessionService.Get("login") == "true")
    {
      next({ name: 'home' })
    } 
    else 
      next()
  }
  else if(to.meta.requiresAuth)
  {
    if (!sessionService.Get("login")) 
      next({ name: 'login' })
    else 
    next()
  }
  else{
    next()
  }
  
})

export default router
