<script setup>
</script>
<template>
<!--begin::Main-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Nota</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Notas</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Info-->
            <!--begin::Nav-->
            <!-- <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
                <a href="apps/chat.html" class="btn btn-active-accent fw-bolder">Chat</a>
                <a href="apps/inbox.html" class="btn btn-active-accent active fw-bolder ms-3">Inbox</a>
                <a href="apps/shop/shop-1.html" class="btn btn-active-accent fw-bolder ms-3">Shop 1</a>
                <a href="apps/shop/shop-2.html" class="btn btn-active-accent fw-bolder ms-3">Shop 2</a>
                <a href="apps/shop/product.html" class="btn btn-active-accent fw-bolder ms-3">Shop Product</a>
            </div> -->
            <!--end::Nav-->
        </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Inbox-->
            <div class="d-flex flex-column flex-lg-row">
                <!--begin::View-->
                <div class="flex-lg-row-fluid ms-lg-12" id="kt_inbox_view">
                    <!--begin::Card-->
                    <div class="card">
                        <!--begin::Header-->
                        <div class="card-header align-items-center flex-wrap justify-content-between">
                            <!--begin::Left-->
                            <div class="d-flex align-items-center">
                                <a href="javascript:;" @click="goToPath('/messages/notes')" class="btn btn-active-light-primary btn-icon btn-sm me-6" data-bs-toggle="tooltip" title="Regresar" data-bs-container="body" data-inbox="back">
                                    <i class="fas fa-arrow-left fs-3"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Favorito" data-bs-container="body">
                                    <i class="fas fa-heart fs-6"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Eliminar" data-bs-container="body">
                                    <i class="fas fa-trash fs-6"></i>
                                </a>
                            </div>
                            <!--end::Left-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body">
                            <!--begin::Header-->
                            <div class="d-flex flex-stack flex-wrap py-5">
                                <!--begin::Title-->
                                <div class="d-flex align-items-center flex-wrap me-2 py-2">
                                    <div class="fw-bold fs-2 me-3">Trip Reminder. Thank you for flying with us!</div>
                                </div>
                            </div>
                            <!--end::Header-->
                            <!--begin::Message-->
                            <div class="mb-3">
                                <div class="d-flex py-6 flex-column flex-md-row flex-lg-column flex-xxl-row justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-40px me-4">
                                            <span class="symbol-label bg-light">
                                                <img src="frontend/assets/media/svg/avatars/015-boy-6.svg" class="h-75 align-self-end" alt="" />
                                            </span>
                                        </div>
                                        <!--end::Symbol-->
                                        <div class="d-flex flex-column flex-grow-1 flex-wrap me-2">
                                            <div class="d-flex align-items-center flex-wrap">
                                                <a href="#" class="fs-6 fw-bolder text-gray-800 text-hover-primary me-2">Chris Muller</a>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <div class="dropdown-menu dropdown-menu-start p-5">
                                                    <table>
                                                        <tr>
                                                            <td class="text-muted min-w-75px py-2">From</td>
                                                            <td>Mark Andre</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted py-2">Date:</td>
                                                            <td>Jul 30, 2019, 11:27 PM</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted py-2">Subject:</td>
                                                            <td>Trip Reminder. Thank you for flying with us!</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted py-2">Reply to:</td>
                                                            <td>mark.andre@gmail.com</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex my-2 my-xxl-0 align-items-md-center align-items-lg-start align-items-xxl-center flex-column flex-md-row flex-lg-column flex-xxl-row">
                                        <div class="fw-bold text-muted mx-2">Jul 15, 2019, 11:19AM</div>
                                    </div>
                                </div>
                                <div class="py-3">
                                    <p>Hi Bob,</p>
                                    <p>With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part of any article is the title.Without a compelleing title, your reader won't even get to the first sentence.After the title, however, the first few sentences of your article are certainly the most important part.</p>
                                    <p>Jornalists call this critical, introductory section the "Lede," and when bridge properly executed, it's the that carries your reader from an headine try at attention-grabbing to the body of your blog post, if you want to get it right on of these 10 clever ways to omen your next blog posr with a bang</p>
                                    <p>Best regards,</p>
                                    <p>Jason Muller</p>
                                </div>
                            </div>
                            <!--end::Message-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::View-->
            </div>
            <!--end::Inbox-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Main-->
</template>

<script>
import { ref } from 'vue'

 
export default {
    name: 'Noteview',
    methods:{
      goToPath(path){
        this.$router.push(path)
      }
  }
}

</script>

<style>

</style>