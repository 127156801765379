<template>
<!--begin::Footer-->
<div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
<!--begin::Container-->
<div class="container d-flex flex-column flex-md-row flex-stack">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-2">2023©</span>
        <a href="https://www.coedeploy.com/" target="_blank" class="text-gray-800 text-hover-primary">Coedeploy</a>
    </div>
    <!--end::Copyright-->
    <!--begin::Nav-->
    <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
            <a href="javascript:;" @click="goToPath('/about')" target="_blank" class="menu-link ps-0 pe-2">Acerca de</a>
        </li>
        <li class="menu-item">
            <a href="javascript:;" @click="goToPath('/contact')" target="_blank" class="menu-link pe-0 pe-2">Contacto</a>
        </li>
    </ul>
    <!--end::Nav-->
</div>
<!--end::Container-->
</div>
<!--end::Footer-->
</template>

<script>
export default {
    name:'_Footer',
    methods:{
      goToPath(path){
        this.$router.push(path)
    }
  }
};
</script>

<style>

</style>