<script setup>
import { onMounted, ref } from 'vue'
import GetData from '@/services/messages/messageService'
const message = ref()
    function RefreshData() {
    GetData("Active").then((response) => {
                message.value = response.data
			});
  }
  onMounted(() => {
    RefreshData()
    
        })
</script>
<template>
  <!--begin::Main-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Recordatorios</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Listado de Recordatorios</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
        </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Inbox-->
            <div class="d-flex flex-column flex-lg-row">
                <!--begin::List-->
                <div class="flex-lg-row-fluid ms-lg-12 d-block" id="kt_inbox_list">
                    <!--begin::Card-->
                    <div class="card mt-10 mt-lg-0">
                        <!--begin::Header-->
                        <div class="card-header border-0 py-5">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bolder text-dark">Recordatorios</span>
                            </h3>
                            <div class="card-toolbar">
                                <a href="javascript:;" @click="goToPath('/messages/createreminder')" class="btn btn-primary fw-bolder fs-7">Nuevo recordatorio</a>
                            </div>
                        </div>
                        <div class="card card-stretch mb-5 mb-xxl-8">
                            <!--begin::Body-->
                            <div class="card-body pt-3">
                                <!--begin::Timeline-->
                                <div class="timeline-label">
                                    <!--begin::Item-->
                                    <div class="timeline-item">
                                        <!--begin::Label-->
                                        <div class="timeline-label fw-bolder text-gray-800 fs-6">08:42</div>
                                        <!--end::Label-->
                                        <!--begin::Badge-->
                                        <div class="timeline-badge">
                                            <i class="fa fa-genderless text-warning fs-1"></i>
                                        </div>
                                        <!--end::Badge-->
                                        <!--begin::Text-->
                                        <div class="fw-mormal timeline-content text-muted ps-3">Outlines keep you honest. And keep structure</div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Item-->
                                </div>
                                <!--end::Timeline-->
                            </div>
                            <!--end: Card Body-->
                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::List-->
            </div>
            <!--end::Inbox-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Main-->
</template>

<script>
export default {
    methods:{
      goToPath(path){
        this.$router.push(path)
      }
    }
}
</script>

<style>

</style>