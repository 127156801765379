<script setup>
import router  from '@/router';
import {provide } from 'vue'
const id = ref(0)
const idfun = (parametter) => {
    id.value = parametter
}
provide('edit', {id, idfun})
</script>
<template>
  <Master v-if="router.currentRoute.value.name!='login'"/>
  <router-view v-else/>
</template>

<script>
import Master from './views/Master.vue';
import sessionService from '@/services/data/sessionService';
import { ref } from 'vue';

export default {
  components:{Master},
 
 
};
</script>