import axios, { AxiosInstance, AxiosResponse } from 'axios';
import sessionService from '@/services/data/sessionService';
const apiUrl = process.env.VUE_APP_API_URL;
const apiService: AxiosInstance = axios.create({
  baseURL: apiUrl, // Reemplaza esto con la URL base de tu API
  // Aquí puedes configurar cualquier otra opción de Axios, como encabezados personalizados, etc.
});

// Agregar un interceptor para enviar el token en las solicitudes
apiService.interceptors.request.use(config => {
  const token = sessionService.Get('token');
  if (token) {
    config.headers['Authorization'] = `bearer ${token}`;
  }
  return config;
});

export default apiService;