
    
    <script setup>
    import { reactive, computed,ref, onMounted} from 'vue'
    import UpdateData from '@/services/users/userServiceUpdate'
    import GetDataShow from '@/services/users/userServiceShow'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import { loadScript } from '@/helpers/helpers';
    import { useRoute } from 'vue-router'
    const information = ref()
    // computed
    const Fullname = ref("");
    const Displayname = ref("");
    const Direction = ref("");
    const Phone = ref("");
    const Country = ref("");
    const Email = ref("");
    const Emailbackup = ref("");
    const Companyid = ref("");
    const route = useRoute()
    
    onMounted(() => {
        GetDataShow(route.params.id).then((response) => {
            information.value = response
            Fullname.value = information.value.fullName
            Displayname.value = information.value.displayName
            Direction.value = information.value.direction
            Phone.value = information.value.phones
            Email.value = information.value.email
            Emailbackup.value = information.value.emailBackup
            Companyid.value = information.value.companyId
        });
     loadScript('./frontend/assets/js/default/components/stepper.js')
             .then(() => {
             console.log("Cargado")
             })
             .catch(() => {
                 console.log("No cargado")
             });
        
         setTimeout(() => {
             loadScript('./frontend/assets/js/default/custom/general/wizard-createuser.js')
             .then(() => {
             console.log("Cargado")
             })
             .catch(() => {
                 console.log("No cargado")
             });
             }, 1000);
     })
     
     
      function UpdateDataUser() {
        //country se manda despues aun no esta en bd
         UpdateData(
             route.params.id,
             Fullname.value,
             Displayname.value,
             Direction.value,
             Phone.value,
             Email.value,
             Emailbackup.value,
             Companyid.value,
             ).then((e) => {
                 console.log(e)
             })
             .catch(() => {
                 console.log("No editado")
             });
     }
 </script>
 <template>
     <!--begin::Main-->
     <div class="d-flex flex-column flex-column-fluid">
         <!--begin::toolbar-->
         <!--begin::toolbar-->
         <div class="toolbar" id="kt_toolbar">
             <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
                 <!--begin::Info-->
                 <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                     <!--begin::Title-->
                     <h3 class="text-dark fw-bolder my-1">Editar</h3>
                     <!--end::Title-->
                     <!--begin::Breadcrumb-->
                     <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                         <li class="breadcrumb-item">
                             <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                         </li>
                         <li class="breadcrumb-item">
                             <a href="javascript:;" @click="goToPath('/users/list')" class="text-muted text-hover-primary">Usuarios</a>
                         </li>
                         <li class="breadcrumb-item text-dark">Editar usuario</li>
                     </ul>
                     <!--end::Breadcrumb-->
                 </div>
             </div>
         </div>
         <!--end::toolbar-->
         <!--end::toolbar-->
         <!--begin::Content-->
         <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
             <!--begin::Container-->
             <div class="container">
                 <!--begin::Card-->
                 <div class="card">
                     <div class="card-header border-0 py-5">
                         <h3 class="card-title align-items-start flex-column">
                         </h3>
                         <div class="card-toolbar">
                             <a href="javascript:;" @click="$router.go(-1)" class="btn btn-primary fw-bolder fs-7">Regresar</a>
                         </div>
                     </div>
                     <!--begin::Card Body-->
                     <div class="card-body p-10 p-lg-15 p-xxl-30">
                         <!--begin::Stepper 1-->
                         <div class="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid" id="kt_stepper">
                             <!--begin::Aside-->
                             <div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px">
                                 <!--begin::Nav-->
                                 <div class="stepper-nav d-flex flex-column py-15">
                                     <!--begin::Step 1-->
                                     <div class="stepper-item current" data-kt-stepper-element="nav">
                                         <div class="stepper-wrapper">
                                             <div class="stepper-icon">
                                                 <i class="stepper-check fas fa-check"></i>
                                                 <span class="stepper-number">1</span>
                                             </div>
                                             <div class="stepper-label">
                                                 <h3 class="stepper-title">Informacion de usuario</h3>
                                                 <div class="stepper-desc">Edita la informacion de el usuario</div>
                                             </div>
                                         </div>
                                     </div>
                                     <!--end::Step 1-->
                                     <!--begin::Step 2-->
                                     <div class="stepper-item" data-kt-stepper-element="nav">
                                         <div class="stepper-wrapper">
                                             <div class="stepper-icon">
                                                 <i class="stepper-check fas fa-check"></i>
                                                 <span class="stepper-number">2</span>
                                             </div>
                                             <div class="stepper-label">
                                                 <h3 class="stepper-title">Edicion de usuario</h3>
                                                 <div class="stepper-desc">Edita las creedenciales del usuario</div>
                                             </div>
                                         </div>
                                     </div>
                                     <!--end::Step 2-->
                                     <!--begin::Step 3-->
                                     <div class="stepper-item" data-kt-stepper-element="nav">
                                         <div class="stepper-wrapper">
                                             <div class="stepper-icon">
                                                 <i class="stepper-check fas fa-check"></i>
                                                 <span class="stepper-number">3</span>
                                             </div>
                                             <div class="stepper-label">
                                                 <h3 class="stepper-title">¡Completado!</h3>
                                                 <div class="stepper-desc">Editar</div>
                                             </div>
                                         </div>
                                     </div>
                                     <!--end::Step 3-->
                                 </div>
                                 <!--end::Nav-->
                             </div>
                             <!--begin::Aside-->
                             <!--begin::Content-->
                             <div class="d-flex flex-row-fluid justify-content-center">
                                 <!--begin::Form-->
                                 <form class="pt-10 w-100 w-md-400px w-xl-500px" novalidate="novalidate" id="kt_stepper_form">
                                     <!--begin::Step 1-->
                                     <div class="current" data-kt-stepper-element="content">
                                         <div class="w-100">
                                             <!--begin::Heading-->
                                             <div class="pb-10 pb-lg-15">
                                                 <h3 class="fw-bolder text-dark display-6">Informacion de usuario</h3>
                                             </div>
                                             <!--begin::Heading-->
                                             <!--begin::Form Group-->
                                             <div class="fv-row mb-10">
                                                 <label class="fs-6 form-label fw-bolder text-dark">Nombre completo</label>
                                                 <input type="text" class="form-control form-control-lg form-control-solid" name="fullName" v-model="Fullname"/>
                                             </div>
                                             <!--end::Form Group-->
                                             <!--begin::Form Group-->
                                             <div class="fv-row mb-10">
                                                 <label class="fs-6 form-label fw-bolder text-dark">Nombre para mostrar</label>
                                                 <input type="text" class="form-control form-control-lg form-control-solid" name="contactname" v-model="Displayname" placeholder=""/>
                                             </div>
                                             <!--end::Form Group-->
                                             <!--begin::Form Group-->
                                             <div class="fv-row mb-10">
                                                 <label class="fs-6 form-label fw-bolder text-dark">Dirección</label>
                                                 <input type="text" class="form-control form-control-lg form-control-solid" name="direction" v-model="Direction" placeholder=""/>
                                             </div>
                                             <!--end::Form Group-->
                                             <!--begin::Form Group-->
                                             <div class="fv-row mb-10">
                                                 <label class="fs-6 form-label fw-bolder text-dark">Télefono</label>
                                                 <input type="text" class="form-control form-control-lg form-control-solid" name="phone" v-model="Phone" placeholder=""/>
                                             </div>
                                             <!--end::Form Group-->
                                             <!--begin::Form Group-->
                                             <div class="fv-row mb-10">
                                                     <!--end::Form Group-->
                                                     <div class="">
                                                         <label class="fs-6 form-label fw-bolder text-dark">Pais</label>
                                                         <select class="form-select form-select-lg form-select-solid" name="country" data-control="select2" v-model="Country" data-placeholder="Seleccionar pais">
                                                             <option value="AF">Afghanistan</option>
                                                             <option value="AX">Åland Islands</option>
                                                             <option value="AL">Albania</option>
                                                             <option value="DZ">Algeria</option>
                                                             <option value="AS">American Samoa</option>
                                                             <option value="AD">Andorra</option>
                                                             <option value="AO">Angola</option>
                                                             <option value="AI">Anguilla</option>
                                                             <option value="AQ">Antarctica</option>
                                                             <option value="AG">Antigua and Barbuda</option>
                                                             <option value="AR">Argentina</option>
                                                             <option value="AM">Armenia</option>
                                                             <option value="AW">Aruba</option>
                                                             <option value="AU" selected="selected">Australia</option>
                                                             <option value="AT">Austria</option>
                                                             <option value="AZ">Azerbaijan</option>
                                                             <option value="BS">Bahamas</option>
                                                             <option value="BH">Bahrain</option>
                                                             <option value="BD">Bangladesh</option>
                                                             <option value="BB">Barbados</option>
                                                             <option value="BY">Belarus</option>
                                                             <option value="BE">Belgium</option>
                                                             <option value="BZ">Belize</option>
                                                             <option value="BJ">Benin</option>
                                                             <option value="BM">Bermuda</option>
                                                             <option value="BT">Bhutan</option>
                                                             <option value="BO">Bolivia, Plurinational State of</option>
                                                             <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                             <option value="BA">Bosnia and Herzegovina</option>
                                                             <option value="BW">Botswana</option>
                                                             <option value="BV">Bouvet Island</option>
                                                             <option value="BR">Brazil</option>
                                                             <option value="IO">British Indian Ocean Territory</option>
                                                             <option value="BN">Brunei Darussalam</option>
                                                             <option value="BG">Bulgaria</option>
                                                             <option value="BF">Burkina Faso</option>
                                                             <option value="BI">Burundi</option>
                                                             <option value="KH">Cambodia</option>
                                                             <option value="CM">Cameroon</option>
                                                             <option value="CA">Canada</option>
                                                             <option value="CV">Cape Verde</option>
                                                             <option value="KY">Cayman Islands</option>
                                                             <option value="CF">Central African Republic</option>
                                                             <option value="TD">Chad</option>
                                                             <option value="CL">Chile</option>
                                                             <option value="CN">China</option>
                                                             <option value="CX">Christmas Island</option>
                                                             <option value="CC">Cocos (Keeling) Islands</option>
                                                             <option value="CO">Colombia</option>
                                                             <option value="KM">Comoros</option>
                                                             <option value="CG">Congo</option>
                                                             <option value="CD">Congo, the Democratic Republic of the</option>
                                                             <option value="CK">Cook Islands</option>
                                                             <option value="CR">Costa Rica</option>
                                                             <option value="CI">Côte d'Ivoire</option>
                                                             <option value="HR">Croatia</option>
                                                             <option value="CU">Cuba</option>
                                                             <option value="CW">Curaçao</option>
                                                             <option value="CY">Cyprus</option>
                                                             <option value="CZ">Czech Republic</option>
                                                             <option value="DK">Denmark</option>
                                                             <option value="DJ">Djibouti</option>
                                                             <option value="DM">Dominica</option>
                                                             <option value="DO">Dominican Republic</option>
                                                             <option value="EC">Ecuador</option>
                                                             <option value="EG">Egypt</option>
                                                             <option value="SV">El Salvador</option>
                                                             <option value="GQ">Equatorial Guinea</option>
                                                             <option value="ER">Eritrea</option>
                                                             <option value="EE">Estonia</option>
                                                             <option value="ET">Ethiopia</option>
                                                             <option value="FK">Falkland Islands (Malvinas)</option>
                                                             <option value="FO">Faroe Islands</option>
                                                             <option value="FJ">Fiji</option>
                                                             <option value="FI">Finland</option>
                                                             <option value="FR">France</option>
                                                             <option value="GF">French Guiana</option>
                                                             <option value="PF">French Polynesia</option>
                                                             <option value="TF">French Southern Territories</option>
                                                             <option value="GA">Gabon</option>
                                                             <option value="GM">Gambia</option>
                                                             <option value="GE">Georgia</option>
                                                             <option value="DE">Germany</option>
                                                             <option value="GH">Ghana</option>
                                                             <option value="GI">Gibraltar</option>
                                                             <option value="GR">Greece</option>
                                                             <option value="GL">Greenland</option>
                                                             <option value="GD">Grenada</option>
                                                             <option value="GP">Guadeloupe</option>
                                                             <option value="GU">Guam</option>
                                                             <option value="GT">Guatemala</option>
                                                             <option value="GG">Guernsey</option>
                                                             <option value="GN">Guinea</option>
                                                             <option value="GW">Guinea-Bissau</option>
                                                             <option value="GY">Guyana</option>
                                                             <option value="HT">Haiti</option>
                                                             <option value="HM">Heard Island and McDonald Islands</option>
                                                             <option value="VA">Holy See (Vatican City State)</option>
                                                             <option value="HN">Honduras</option>
                                                             <option value="HK">Hong Kong</option>
                                                             <option value="HU">Hungary</option>
                                                             <option value="IS">Iceland</option>
                                                             <option value="IN">India</option>
                                                             <option value="ID">Indonesia</option>
                                                             <option value="IR">Iran, Islamic Republic of</option>
                                                             <option value="IQ">Iraq</option>
                                                             <option value="IE">Ireland</option>
                                                             <option value="IM">Isle of Man</option>
                                                             <option value="IL">Israel</option>
                                                             <option value="IT">Italy</option>
                                                             <option value="JM">Jamaica</option>
                                                             <option value="JP">Japan</option>
                                                             <option value="JE">Jersey</option>
                                                             <option value="JO">Jordan</option>
                                                             <option value="KZ">Kazakhstan</option>
                                                             <option value="KE">Kenya</option>
                                                             <option value="KI">Kiribati</option>
                                                             <option value="KP">Korea, Democratic People's Republic of</option>
                                                             <option value="KR">Korea, Republic of</option>
                                                             <option value="KW">Kuwait</option>
                                                             <option value="KG">Kyrgyzstan</option>
                                                             <option value="LA">Lao People's Democratic Republic</option>
                                                             <option value="LV">Latvia</option>
                                                             <option value="LB">Lebanon</option>
                                                             <option value="LS">Lesotho</option>
                                                             <option value="LR">Liberia</option>
                                                             <option value="LY">Libya</option>
                                                             <option value="LI">Liechtenstein</option>
                                                             <option value="LT">Lithuania</option>
                                                             <option value="LU">Luxembourg</option>
                                                             <option value="MO">Macao</option>
                                                             <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                                             <option value="MG">Madagascar</option>
                                                             <option value="MW">Malawi</option>
                                                             <option value="MY">Malaysia</option>
                                                             <option value="MV">Maldives</option>
                                                             <option value="ML">Mali</option>
                                                             <option value="MT">Malta</option>
                                                             <option value="MH">Marshall Islands</option>
                                                             <option value="MQ">Martinique</option>
                                                             <option value="MR">Mauritania</option>
                                                             <option value="MU">Mauritius</option>
                                                             <option value="YT">Mayotte</option>
                                                             <option value="MX">Mexico</option>
                                                             <option value="FM">Micronesia, Federated States of</option>
                                                             <option value="MD">Moldova, Republic of</option>
                                                             <option value="MC">Monaco</option>
                                                             <option value="MN">Mongolia</option>
                                                             <option value="ME">Montenegro</option>
                                                             <option value="MS">Montserrat</option>
                                                             <option value="MA">Morocco</option>
                                                             <option value="MZ">Mozambique</option>
                                                             <option value="MM">Myanmar</option>
                                                             <option value="NA">Namibia</option>
                                                             <option value="NR">Nauru</option>
                                                             <option value="NP">Nepal</option>
                                                             <option value="NL">Netherlands</option>
                                                             <option value="NC">New Caledonia</option>
                                                             <option value="NZ">New Zealand</option>
                                                             <option value="NI">Nicaragua</option>
                                                             <option value="NE">Niger</option>
                                                             <option value="NG">Nigeria</option>
                                                             <option value="NU">Niue</option>
                                                             <option value="NF">Norfolk Island</option>
                                                             <option value="MP">Northern Mariana Islands</option>
                                                             <option value="NO">Norway</option>
                                                             <option value="OM">Oman</option>
                                                             <option value="PK">Pakistan</option>
                                                             <option value="PW">Palau</option>
                                                             <option value="PS">Palestinian Territory, Occupied</option>
                                                             <option value="PA">Panama</option>
                                                             <option value="PG">Papua New Guinea</option>
                                                             <option value="PY">Paraguay</option>
                                                             <option value="PE">Peru</option>
                                                             <option value="PH">Philippines</option>
                                                             <option value="PN">Pitcairn</option>
                                                             <option value="PL">Poland</option>
                                                             <option value="PT">Portugal</option>
                                                             <option value="PR">Puerto Rico</option>
                                                             <option value="QA">Qatar</option>
                                                             <option value="RE">Réunion</option>
                                                             <option value="RO">Romania</option>
                                                             <option value="RU">Russian Federation</option>
                                                             <option value="RW">Rwanda</option>
                                                             <option value="BL">Saint Barthélemy</option>
                                                             <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                                             <option value="KN">Saint Kitts and Nevis</option>
                                                             <option value="LC">Saint Lucia</option>
                                                             <option value="MF">Saint Martin (French part)</option>
                                                             <option value="PM">Saint Pierre and Miquelon</option>
                                                             <option value="VC">Saint Vincent and the Grenadines</option>
                                                             <option value="WS">Samoa</option>
                                                             <option value="SM">San Marino</option>
                                                             <option value="ST">Sao Tome and Principe</option>
                                                             <option value="SA">Saudi Arabia</option>
                                                             <option value="SN">Senegal</option>
                                                             <option value="RS">Serbia</option>
                                                             <option value="SC">Seychelles</option>
                                                             <option value="SL">Sierra Leone</option>
                                                             <option value="SG">Singapore</option>
                                                             <option value="SX">Sint Maarten (Dutch part)</option>
                                                             <option value="SK">Slovakia</option>
                                                             <option value="SI">Slovenia</option>
                                                             <option value="SB">Solomon Islands</option>
                                                             <option value="SO">Somalia</option>
                                                             <option value="ZA">South Africa</option>
                                                             <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                             <option value="SS">South Sudan</option>
                                                             <option value="ES">Spain</option>
                                                             <option value="LK">Sri Lanka</option>
                                                             <option value="SD">Sudan</option>
                                                             <option value="SR">Suriname</option>
                                                             <option value="SJ">Svalbard and Jan Mayen</option>
                                                             <option value="SZ">Swaziland</option>
                                                             <option value="SE">Sweden</option>
                                                             <option value="CH">Switzerland</option>
                                                             <option value="SY">Syrian Arab Republic</option>
                                                             <option value="TW">Taiwan, Province of China</option>
                                                             <option value="TJ">Tajikistan</option>
                                                             <option value="TZ">Tanzania, United Republic of</option>
                                                             <option value="TH">Thailand</option>
                                                             <option value="TL">Timor-Leste</option>
                                                             <option value="TG">Togo</option>
                                                             <option value="TK">Tokelau</option>
                                                             <option value="TO">Tonga</option>
                                                             <option value="TT">Trinidad and Tobago</option>
                                                             <option value="TN">Tunisia</option>
                                                             <option value="TR">Turkey</option>
                                                             <option value="TM">Turkmenistan</option>
                                                             <option value="TC">Turks and Caicos Islands</option>
                                                             <option value="TV">Tuvalu</option>
                                                             <option value="UG">Uganda</option>
                                                             <option value="UA">Ukraine</option>
                                                             <option value="AE">United Arab Emirates</option>
                                                             <option value="GB">United Kingdom</option>
                                                             <option value="US">United States</option>
                                                             <option value="UM">United States Minor Outlying Islands</option>
                                                             <option value="UY">Uruguay</option>
                                                             <option value="UZ">Uzbekistan</option>
                                                             <option value="VU">Vanuatu</option>
                                                             <option value="VE">Venezuela, Bolivarian Republic of</option>
                                                             <option value="VN">Viet Nam</option>
                                                             <option value="VG">Virgin Islands, British</option>
                                                             <option value="VI">Virgin Islands, U.S.</option>
                                                             <option value="WF">Wallis and Futuna</option>
                                                             <option value="EH">Western Sahara</option>
                                                             <option value="YE">Yemen</option>
                                                             <option value="ZM">Zambia</option>
                                                             <option value="ZW">Zimbabwe</option>
                                                         </select>
                                                     </div>
                                                     <!--end::Form Group-->
                                             </div>
                                             <!--end::Form Group-->
                                         </div>
                                     </div>
                                     <!--end::Step 1-->
                                     <!--begin::Step 2-->
                                     <div class="" data-kt-stepper-element="content">
                                         <div class="w-100">
                                             <!--begin::Heading-->
                                             <div class="pb-10 pb-lg-15">
                                                 <h3 class="fw-bolder text-dark display-6">Edicion de usuario</h3>
                                             </div>
                                             <!--end::Heading-->
                                             <div class="fv-row mb-10">
                                                 <label class="fs-6 form-label fw-bolder text-dark">Correo eléctronico</label>
                                                 <input type="text" class="form-control form-control-lg form-control-solid" name="email" v-model="Email" placeholder="" />
                                             </div>
                                              <!--begin::Form Group-->
                                             <div class="fv-row mb-10">
                                                 <label class="fs-6 form-label fw-bolder text-dark">Correo eléctronico de recuperacion</label>
                                                 <input type="text" class="form-control form-control-lg form-control-solid" name="emailbackup" v-model="Emailbackup" placeholder="" />
                                             </div>
                                             <!--end::Form Group-->
                                             <div class="fv-row mb-10">
                                                 <label class="fs-6 form-label fw-bolder text-dark">Compañia</label>
                                                 <select class="form-select form-select-lg form-select-solid" name="Companyid" data-control="select2" v-model="Companyid" data-placeholder="Seleccionar pais">
                                                     <option value="1">MC</option>
                                                     <option value="2">Burguer</option>
                                                     <option value="3">Telus</option>
                                                 </select>
                                             </div>
                                             <!--end::Form Group-->
                                             <!--end::Form Group-->
                                             
                                             <!--begin::Form Group-->
                                         </div>
                                     </div>
                                     <!--end::Step 2-->
                                     <!--begin::Step 5-->
                                     <div class="" data-kt-stepper-element="content">
                                         <div class="w-100">
                                             <!--begin::Heading-->
                                             <div class="pb-10 pb-lg-15">
                                                 <h3 class="fw-bolder text-dark display-6">Completado</h3>
                                                 <div class="text-muted fw-bold fs-3">¡Completa el registro para editar el usuario!</div>
                                                 <div>Nombre completo: <span>{{ Fullname }}</span></div>
                                                 <div>Nombre para mostrar: <span>{{ Displayname }}</span></div>
                                                 <div>Dirección: <span>{{ Direction }}</span></div>
                                                 <div>Télefono: <span>{{ Phone }}</span></div>
                                                 <div>País: <span>{{ Country }}</span></div>
                                                 <div>Correo eléctronico: <span>{{ Email }}</span></div>
                                                 <div>Correo eléctronico de seguridad: <span>{{ Emailbackup }}</span></div>
                                                 <div>Compañia: <span>{{ Companyid }}</span></div>
                                               
                                             </div>
                                         </div>
                                     </div>
                                     <!--end::Step 5-->
                                     <!--begin::Actions-->
                                     <div class="d-flex justify-content-between pt-10">
                                         <div class="mr-2">
                                             <button type="button" class="btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3" data-kt-stepper-action="previous">
                                             <!--begin::Svg Icon | path: icons/stockholm/Navigation/Left-2.svg-->
                                             <span class="svg-icon svg-icon-4 me-1">
                                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                     <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                         <polygon points="0 0 24 0 24 24 0 24" />
                                                         <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x="14" y="7" width="2" height="10" rx="1" />
                                                         <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                                                     </g>
                                                 </svg>
                                             </span>
                                             <!--end::Svg Icon-->Anterior</button>
                                         </div>
                                         <div>
                                             <button type="button" class="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3" @click="UpdateDataUser()" >Editar
                                             <!--begin::Svg Icon | path: icons/stockholm/Navigation/Right-2.svg-->
                                             <span class="svg-icon svg-icon-4 ms-2">
                                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                     <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                         <polygon points="0 0 24 0 24 24 0 24" />
                                                         <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                         <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                     </g>
                                                 </svg>
                                             </span>
                                             <!--end::Svg Icon--></button>
                                             <button type="button" class="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3" data-kt-stepper-action="next">Siguiente
                                             <!--begin::Svg Icon | path: icons/stockholm/Navigation/Right-2.svg-->
                                             <span class="svg-icon svg-icon-4 ms-1">
                                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                     <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                         <polygon points="0 0 24 0 24 24 0 24" />
                                                         <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                         <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                     </g>
                                                 </svg>
                                             </span>
                                             <!--end::Svg Icon--></button>
                                         </div>
                                     </div>
                                     <!--end::Actions-->
                                 </form>
                                 <!--end::Form-->
                             </div>
                             <!--end::Content-->
                         </div>
                         <!--end::Stepper 1-->
                     </div>
                     <!--end::Card Body-->
                 </div>
                 <!--end::Card-->
             </div>
             <!--end::Container-->
         </div>
         <!--end::Content-->
     </div>
     <!--end::Main-->
 </template>
 
 <script>
 export default {
     methods:{
       goToPath(path){
         this.$router.push(path)
       }
     }
 }
 </script>
 
 <style>
 
 </style>