<template>
    <!--begin::Main-->
  <div class="d-flex flex-column flex-column-fluid">
      <!--begin::toolbar-->
      <div class="toolbar" id="kt_toolbar">
          <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
              <!--begin::Info-->
              <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                  <!--begin::Title-->
                  <h3 class="text-dark fw-bolder my-1">Articulos</h3>
                  <!--end::Title-->
                  <!--begin::Breadcrumb-->
                  <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                      <li class="breadcrumb-item">
                          <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                      </li>
                      <li class="breadcrumb-item text-dark">Articulo</li>
                  </ul>
                  <!--end::Breadcrumb-->
              </div>
              <!--end::Info-->
          </div>
      </div>
      <!--end::toolbar-->
      <!--begin::Content-->
      <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
							<!--begin::Container-->
							<div class="container-xxl">
								<!--begin::Layout-->
								<div class="flex-row-fluid">
									<!--begin::Section-->
									<!--begin::Engage Widget 13-->
									<!--begin::Card-->
									<div class="card mb-5 mb-xxl-8">
										<!--begin::Card Body-->
										<div class="card-body d-flex bg-white p-12 flex-column flex-md-row flex-lg-column flex-xxl-row">
											<!--begin::Image-->
											<div class="bgi-no-repeat bgi-position-center bgi-size-cover h-300px h-md-auto h-lg-300px h-xxl-auto mw-100 w-650px mx-auto" style="background-image: url('assets/media/products/12.png')"></div>
											<!--end::Image-->
											<!--begin::Card-->
											<div class="card shadow-none w-auto w-md-300px w-lg-auto w-xxl-300px ml-auto">
												<!--begin::Card Body-->
												<div class="card-body bg-light px-12 py-10">
													<h3 class="fw-bolder fs-1 mb-1">
														<a href="#" class="text-gray-800">Nombre articulo</a>
													</h3>
													<div class="text-primary fs-3 mb-9">$ 399.99</div>
													<div class="fs-7 mb-8">Outlines keep you honest. They stop you from indulging in poorly ought out metaphorsy about driving and keep you focused one the overall structure of your post</div>
													<!--begin::Info-->
													<table class="table table-borderless align-middle fw-bold">
														<tr>
															<td class="text-gray-600 ps-0">Shoes Brand</td>
															<td class="text-dark pe-0">Nike</td>
														</tr>
														<tr>
															<td class="text-gray-600 ps-0">SKU</td>
															<td class="text-dark pe-0">NF3535</td>
														</tr>
														<tr>
															<td class="text-gray-600 ps-0">Color</td>
															<td class="text-dark pe-0">White</td>
														</tr>
														<tr>
															<td class="text-gray-600 ps-0">Collection</td>
															<td class="text-dark pe-0">2020 Spring</td>
														</tr>
														<tr>
															<td class="text-gray-600 ps-0">In Stock</td>
															<td class="text-dark pe-0">280</td>
														</tr>
													</table>
													<!--end::Info-->
												</div>
												<!--end::Card Body-->
											</div>
											<!--end::Card-->
										</div>
										<!--end::Card Body-->
									</div>
									<!--end::Card-->
									<!--end::Engage Widget 13-->
									<!--end::Section-->
								</div>
								<!--end::Layout-->
							</div>
							<!--end::Container-->
        </div>
        <!--end::Content-->
  </div>
  <!--end::Main-->
  </template>
  
  <script>
  export default {
      methods:{
        goToPath(path){
          this.$router.push(path)
        }
      }
  }
  </script>
  
  <style>
  
  </style>