<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { loadScript } from '@/helpers/helpers';
import GetData from '@/services/messages/messageService'
onMounted(() => {
    loadScript('./frontend/assets/js/components/blockui.js')
            .then(() => {
                
            })
            .catch(() => {
                console.log("No cargado")
            });
    RefreshData()
    })
// import  loadScript  from 'load-script';
    const message = ref()
    const LoadData = ref(true)
    const carga = ref(0)
  let intervalId
   function RefreshData() {
    LoadData.value = true
    GetData("All").then((response) => {
        if(response.data.length != 0){
            carga.value = 1
        }
        console.log(response.data)
        message.value = response.data
        LoadData.value = false    
	});
  }
</script>
<template>
  <!--begin::Main-->
<div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                <!--begin::Title-->
                <h3 class="text-dark fw-bolder my-1">Correos</h3>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                    <li class="breadcrumb-item">
                        <a href="javascript:;" @click="goToPath('/')" class="text-muted text-hover-primary">Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-dark">Todos</li>
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Info-->
            <!--begin::Nav-->
            <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
                <a class="nav-link justify-content-center w-60px active" href="javascript:;" @click="goToPath('/messages/spam')" data-bs-toggle="tooltip" title="Correos no deseados" data-bs-container="body">
                    <span class="nav-icon m-0">
                        <!--begin::Svg Icon | path: icons/duotune/communication/com010.svg-->
                        <span class="svg-icon svg-icon-3">
                            <i class="fas fa-envelope fa-lg"></i>
                        </span>
                        <!--end::Svg Icon-->
                    </span>
                </a>
                <a class="nav-link justify-content-center w-60px ms-0" href="javascript:;" @click="goToPath('/messages/schedule')" data-bs-toggle="tooltip" title="Programados" data-bs-container="body">
                    <span class="nav-icon m-0">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen016.svg-->
                        <span class="svg-icon svg-icon-3">
                            <i class="far fa-calendar fa-lg"></i>
                        </span>
                        <!--end::Svg Icon-->
                    </span>
                </a>
                <a class="nav-link justify-content-center w-60px ms-0"  href="javascript:;" @click="goToPath('/messages/favorites')" data-bs-toggle="tooltip" title="Favoritos" data-bs-container="body">
                    <span class="nav-icon m-0">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                        <span class="svg-icon svg-icon-3">
                            <i class="fas fa-star fa-lg"></i>
                        </span>
                        <!--end::Svg Icon-->
                    </span>
                </a>
                <a class="nav-link justify-content-center w-60px ms-0" href="javascript:;" @click="goToPath('/messages/all')" data-bs-toggle="tooltip" title="Todos los correos" data-bs-container="body">
                    <span class="nav-icon m-0">
                        <!--begin::Svg Icon | path: icons/duotune/art/art006.svg-->
                        <span class="svg-icon svg-icon-3">
                            <i class="fas fa-inbox fa-lg"></i>
                        </span>
                        <!--end::Svg Icon-->
                    </span>
                </a>
            </div>
            <!--end::Nav-->
        </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Inbox-->
            <div class="d-flex flex-column flex-lg-row">
                <!--begin::List-->
                <div class="flex-lg-row-fluid ms-lg-12 d-block" id="kt_inbox_list">
                    <!--begin::Card-->
                    <div class="card mt-10 mt-lg-0">
                        <!--begin::Header-->
                        <div class="card-header align-items-center card-px">
                            <!--begin::Toolbar-->
                            <div class="d-flex align-items-center">
                                <div class="form-check form-check-sm form-check-custom form-check-solid me-4 my-2" data-inbox="group-select">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                </div>
                                <div class="d-flex align-items-center me-1 my-2">
                                    <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Favoritos">
                                        <i class="fas fa-heart fs-6"></i>
                                    </a>
                                    <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Responder">
                                        <i class="fas fa-reply fs-6"></i>
                                    </a>
                                    <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Borrar">
                                        <i class="fas fa-trash fs-6"></i>
                                    </a>
                                </div>
                            </div>
                            <!--end::Toolbar-->
                            <!--begin::Pagination-->
                            <div class="d-flex align-items-center justify-content-sm-end text-end my-2">
                                <!--begin::Per Page Dropdown-->
                                <div class="d-flex align-items-center me-2">
                                    <span class="text-muted fw-bold me-2">1/247</span>
                                </div>
                                <!--end::Per Page Dropdown-->
                                <!--begin::Arrow Buttons-->
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Página Anterior" data-bs-container="body">
                                    <i class="fas fa-chevron-left fs-6"></i>
                                </a>
                                <a href="#" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Siguiente Página" data-bs-container="body">
                                    <i class="fas fa-chevron-right fs-6"></i>
                                </a>
                                <a @click="RefreshData()" class="btn btn-icon btn-active-light-primary btn-sm me-2" data-bs-toggle="tooltip" title="Actualizar" data-bs-container="body">
                                    <i style="font-size: 18px;" class="fa fa-redo-alt"></i>
                                </a>
                                <!--end::Arrow Buttons-->
                            </div>
                            <!--end::Pagination-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body px-0 overflow-auto" v-bind:class="{ 'overlay overlay-block': LoadData }">
                            <!--begin::Items-->
                            <div class="min-w-700px min-h-300px" data-inbox="list" v-if="message && !LoadData">
                                <!--begin::Item-->
                                <div class="d-flex align-items-start bg-hover-light card-px py-3" @click="goToPath('/messages/reply')" :class="{ active: index==0 }" data-action="list" v-for="(item, index) in message" v-if="carga != 0">
                                    <!--begin::Toolbar-->
                                    <div class="d-flex align-items-center">
                                        <!--begin::Actions-->
                                        <div class="d-flex align-items-center me-3" data-inbox="actions">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input class="form-check-input" type="checkbox" value="1" />
                                            </div>
                                            <a href="javascript:;" class="btn btn-icon btn-sm text-hover-warning active" data-bs-toggle="tooltip" data-bs-placement="right" title="Star">
                                                <i class="fas fa-star text-muted"></i>
                                            </a>
                                            <a href="javascript:;" class="btn btn-icon btn-sm text-hover-warning" data-bs-toggle="tooltip" data-bs-placement="right" title="Mark as important">
                                                <i class="fas fa-bookmark text-muted"></i>
                                            </a>
                                        </div>
                                        <!--end::Actions-->
                                        <!--begin::Author-->
                                        <div class="d-flex align-items-center flex-wrap w-xxl-200px me-3" data-bs-toggle="view">
                                            <!--begin::Symbol-->
                                            <div class="symbol symbol-40px me-4">
                                                <span class="symbol-label bg-light">
                                                    <img src="frontend/assets/media/svg/avatars/035-boy-15.svg" class="h-75 align-self-end" alt="" />
                                                </span>
                                            </div>
                                            <!--end::Symbol-->
                                            <a class="fw-bold text-gray-800 text-hover-primary">{{ item.userFromName }}</a>
                                        </div>
                                        <!--end::Author-->
                                    </div>
                                    
                                    <!--end::Toolbar-->
                                    <!--begin::Info-->
                                    <div class="flex-grow-1 mt-2 me-2" data-bs-toggle="view">
                                        <div>
                                            <span class="fw-bolder fs-6 me-2">{{ item.subject }}</span>
                                            <span class="text-muted">{{ item.message }}</span>
                                        </div>
                                        <div class="mt-2">
                                            <span class="badge badge-light-primary me-1">inbox</span>
                                            <span class="badge badge-light-danger">task</span>
                                        </div>
                                    </div>
                                    <!--end::Info-->
                                    <!--begin::Datetime-->
                                    <div class="mt-2 me-3 fw-bolder w-50px text-end" data-bs-toggle="view">{{ item.time }}</div>
                                    <!--end::Datetime-->
                                </div>
                                <!--end::Item-->
                                <div  align = "center" v-else><h2>No existen correos para mostrar</h2></div>
                            </div>
                            <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="LoadData">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <!--end::Items-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::List-->
            </div>
            <!--end::Inbox-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Content-->
</div>
<!--end::Main-->
</template>

<script>
export default {
    methods:{
      goToPath(path){
        this.$router.push(path)
      }
    }
}
</script>

<style>

</style>